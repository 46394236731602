import React from "react";
import PropTypes from "prop-types";
import { useLocation, useParams } from "react-router-dom";

import { DefaultLoader, constant } from "@rdcs/dap-front-library";

import moment from "moment";

import { connect } from "react-redux";

import { trySinglePatient } from "../../actions/patients";
import { tryFetchMeasures } from "../../actions/measures";
import { tryFetchLastDiagnosis } from "../../actions/patients";

import BloodSugarHeaderContent from "./BloodSugarHeaderContent";

const headerLoadPath = ["dashboard"];

const BloodSugarHeader = ({ singlePatient, patientLastDiagnosis, measures, tryFetchLastDiagnosis, tryFetchMeasures }) => {
    const { id } = useParams();
    const { pathname } = useLocation();
    const patientId = React.useMemo(() => parseInt(id), [id]);
    const loadHeaderDatas = React.useMemo(() => {
        const paths = pathname.split("/");

        return headerLoadPath.includes(paths[paths.length - 1].toLowerCase());
    });
    const headerDatasIsLoaded = React.useMemo(() => Boolean(singlePatient), [singlePatient]);

    const [measuresLoading, setMeasuresLoading] = React.useState(true);
    const [lastDiagnosisLoading, setLastDiagnosisLoading] = React.useState(true);

    const patientHbA1c = React.useMemo(
        () => measures.filter((measure) => measure.type.name === "HbA1c").sort((a, b) => (a.date > b.date ? -1 : 1))[0],
        [measures]
    );

    const asgGoalSection = React.useMemo(
        () =>
            lastDiagnosisLoading ? (
                <DefaultLoader />
            ) : (
                <>
                    <div className="title-2">
                        {patientLastDiagnosis?.treatmentGroup?.asgGoal && patientLastDiagnosis?.treatmentGroup.asgGoalUnit
                            ? `${patientLastDiagnosis?.treatmentGroup.asgGoal}/${constant("asgGoalUnits", patientLastDiagnosis?.treatmentGroup.asgGoalUnit)}`
                            : "-"}
                    </div>
                    <div className="dashboardAction__daysAgo">
                        <span className="dashboardAction__percentage mr-1">
                            {!singlePatient.bgmAverageDate || !singlePatient.bgmAverage / moment().diff(moment(singlePatient.bgmAverageDate, "h")) > 48
                                ? "-"
                                : singlePatient.bgmAverage.toFixed(2)}
                            /j
                        </span>
                        {singlePatient.bgmAverageDate ? moment(singlePatient.bgmAverageDate).fromNow() : "Il y a -j"}
                    </div>
                </>
            ),
        [lastDiagnosisLoading, loadHeaderDatas, singlePatient]
    );

    React.useEffect(() => {
        if (!patientLastDiagnosis) {
            tryFetchMeasures(patientId).finally(() => setMeasuresLoading(false));
            tryFetchLastDiagnosis(patientId).finally(() => setLastDiagnosisLoading(false));
        } else if (headerDatasIsLoaded) {
            setMeasuresLoading(false);
            setLastDiagnosisLoading(false);
        } else {
            setMeasuresLoading(false);
            setLastDiagnosisLoading(false);
        }
    }, []);

    return (
        Boolean(loadHeaderDatas || singlePatient) && (
            <div className="bloodSugarHeader dashboardHeader">
                <BloodSugarHeaderContent
                    title={
                        <>
                            Objectif <span className="text-transform-none">HbA1c</span>
                        </>
                    }
                >
                    {measuresLoading ? (
                        <DefaultLoader />
                    ) : (
                        <>
                            <div className="title-2">{singlePatient?.lastDiagnostic ? <span>{singlePatient.lastDiagnostic.goal.toFixed(1)}</span> : "-"}%</div>
                            <div className="dashboardAction__daysAgo">
                                <span className="dashboardAction__percentage mr-1">{patientHbA1c ? parseFloat(patientHbA1c.value).toFixed(1) : "-"}%</span>
                                {patientHbA1c ? moment(patientHbA1c.date).fromNow() : "Il y a -j"}
                            </div>
                        </>
                    )}
                </BloodSugarHeaderContent>
                <BloodSugarHeaderContent title="Objectif ASG" hasSeparator={false}>
                    {asgGoalSection}
                </BloodSugarHeaderContent>
            </div>
        )
    );
};

BloodSugarHeader.propTypes = {
    singlePatient: PropTypes.object,
    patientLastDiagnosis: PropTypes.object.isRequired,
    measures: PropTypes.arrayOf(PropTypes.object).isRequired,
    tryFetchLastDiagnosis: PropTypes.func.isRequired,
    trySinglePatient: PropTypes.func.isRequired,
    tryFetchMeasures: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, patients: { singlePatient, patientLastDiagnosis }, measures: { list: measures } }) => ({
    auth,
    singlePatient,
    patientLastDiagnosis,
    measures,
});

const mapDispatchToProps = {
    tryFetchLastDiagnosis,
    trySinglePatient,
    tryFetchMeasures,
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(BloodSugarHeader));
