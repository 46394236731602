import React from "react";

import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";

import { Button, InputImage, InputText, SelectMultiple, Icon, InputRichText, Form, InputImages } from "@rdcs/dap-front-library";

import API from "../../../utils/API";

const ComplicationForm = ({
    complications,
    editedComplication,
    editedComplication: {
        parent: parentComplication = null,
        name: nameComplication = "",
        tooltipDescription: tooltipDescriptionComplication,
        tooltipImages: tooltipImagesComplication,
        icon: iconComplication = null,
    } = {},
    onSubmit,
    errors: { name: nameError = "", description: descriptionError = "", icon: iconError = "" },
    isLoading,
}) => {
    const location = useLocation();

    const [icon, setIcon] = React.useState(iconComplication && iconComplication["@id"]);
    const [tooltipImages, setTooltipImages] = React.useState(
        (tooltipImagesComplication || []).map((tooltipImageComplication) => tooltipImageComplication["@id"])
    );

    const onSubmitCallback = (form) => {
        const tooltipImagesFinal = [];

        for (let i = 0; i < tooltipImages.length; ++i) {
            const image = { id: tooltipImages[i] };
            const source = form[`source${i}`] || null;

            if (source !== null) {
                image.source = source;
            }

            tooltipImagesFinal.push(image);
            delete form[`source${i}`];
        }

        if (tooltipImagesFinal.length > 0) {
            form.tooltipImages = tooltipImagesFinal;
        }

        if (form.tooltipDescription.length === 0) {
            delete form.tooltipDescription;
        }

        if (form.tooltipDescription || form.tooltipImages) {
            form.tooltipTitle = form.name;
        }

        onSubmit({
            ...form,
            parent: form.parent === -1 ? null : form.parent,
            icon,
        });
    };

    const getDisabledIds = React.useCallback(
        (editedItem, ids = []) => {
            ids.push(editedItem.id);
            complications.forEach((item) => {
                if (item.parent && item.parent.id === editedItem.id) {
                    getDisabledIds(item, ids);
                }
            });
            return ids;
        },
        [complications]
    );

    const getComplicationsOptions = React.useMemo(() => {
        const firstChoiceOption = {
            id: -1,
            title: "Aucune complication parente",
            value: -1,
            key: -1,
            pId: 0,
        };

        const disabledIds = editedComplication ? getDisabledIds(editedComplication) : [];

        return [
            firstChoiceOption,
            ...complications.map((item) => ({
                id: item.id,
                title: item.name + (!item.active ? " (Désactivé)" : ""),
                value: item["@id"],
                key: item["@id"],
                pId: item.parent ? item.parent.id : 0,
                disabled: disabledIds.includes(item.id),
            })),
        ];
    }, [editedComplication, getDisabledIds, complications]);

    const uploadIconAction = (file) =>
        API.uploadFile(file)
            .then((item) => setIcon(item["@id"]))
            .catch(() => {
                setIcon(null);
                throw new Error("L'upload a échoué pour une raison inconnue");
            });

    return (
        <Form onSubmit={onSubmitCallback}>
            <div className="col-100">
                <SelectMultiple
                    label="Complication parente"
                    placeholder="Sélectionnez une complication"
                    multiple={false}
                    defaultSelected={(parentComplication && parentComplication["@id"]) || null}
                    options={getComplicationsOptions}
                    name="parent"
                />
            </div>
            <div className="col-100">
                <InputText label="Nom" error={nameError} placeholder="Saisissez un nom de complication" defaultValue={nameComplication} required name="name" />
            </div>
            <div className="col-100">
                <InputRichText
                    label="Description infobulle"
                    error={descriptionError}
                    placeholder="Saisissez une description pour la complication"
                    defaultValue={tooltipDescriptionComplication}
                    name="tooltipDescription"
                />
            </div>
            <div className="col-100">
                <InputImages
                    label="Image(s) en infobulle"
                    uploadAction={API.uploadFile}
                    onChange={setTooltipImages}
                    defaultImages={tooltipImagesComplication}
                    text="Télécharger"
                />
            </div>
            {tooltipImages.map((_, i) => (
                <div key={i} className="col-100">
                    <InputText
                        label="Source de l'image"
                        placeholder="Saisissez la source de l'image"
                        defaultValue={(tooltipImagesComplication || [])[i]?.source || ""}
                        name={`source${i}`}
                    />
                </div>
            ))}
            <div className="col-100">
                <InputImage
                    label="Icône de la complication"
                    uploadAction={uploadIconAction}
                    defaultFilename={iconComplication && iconComplication.filename}
                    error={iconError}
                    text="Télécharger"
                />
            </div>
            <div className="form__group col-100 text-center">
                <Link
                    to={{
                        pathname: "/configuration",
                        state: { from: location },
                        tab: "complications",
                    }}
                >
                    <Button className="button__secondaryButton">
                        <Icon name="arrow-left" className="mr-1" /> Retour
                    </Button>
                </Link>
                <Button type="submit" className="button__primaryButton" isLoading={isLoading}>
                    Enregistrer
                </Button>
            </div>
        </Form>
    );
};

ComplicationForm.propTypes = {
    complications: PropTypes.arrayOf(
        PropTypes.shape({
            parent: PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            }),
        })
    ).isRequired,
    editedComplication: PropTypes.shape({
        "@id": PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        parent: PropTypes.shape({ "@id": PropTypes.string }),
        name: PropTypes.string,
        description: PropTypes.string,
        icon: PropTypes.shape({ "@id": PropTypes.string, filename: PropTypes.string }),
    }),
    onSubmit: PropTypes.func.isRequired,
    errors: PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
        icon: PropTypes.string,
    }).isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export default ComplicationForm;
