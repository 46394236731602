import React from "react";
import PropTypes from "prop-types";
import { Navigate, useLocation } from "react-router-dom";

import { connect } from "react-redux";

import { tryLogout } from "../../actions/auth";

const LogoutScreen = ({ tryLogout }) => {
    const location = useLocation();
    React.useEffect(() => {
        tryLogout();
    }, []);

    return <Navigate to="/connexion" state={{ from: location }} />;
};

LogoutScreen.propTypes = {
    tryLogout: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    tryLogout,
};

export default connect(null, mapDispatchToProps)(LogoutScreen);
