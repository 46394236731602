import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import { Animated } from "react-animated-css";
import { Icon, Form, StepButtons, InputRadio, InputSymbol, DoctorButton, PractitionerButton, Gauge, ButtonUpload, IconButton } from "@rdcs/dap-front-library";
import LeftImage from "@rdcs/dap-front-library/static/images/illu_pharma.svg";
import API from "../../../../utils/API";

const eligibleOptions = [
    { label: "Oui", value: true },
    { label: "Non", value: false },
];

const formId = "break-score-questionnaire-step3-form";

const Step3 = ({ prevStep, isEligible, isLoading, onSave, onDelete, patient, tryEditPotentialPatient }) => {
    const navigate = useNavigate();
    const eligible = React.useMemo(
        () =>
            isEligible
                ? {
                      state: "Éligible",
                      considered: "au dessus",
                      icon: "patient-eligible",
                      button: "Enregistrer",
                      buttonIcon: "check-circle",
                      formId: "patient-final-form",
                  }
                : {
                      state: "Non éligible",
                      considered: "en dessous",
                      icon: "patient-non-eligible",
                      button: "Enregistrer",
                      buttonIcon: "check-circle",
                      formId: "patient-final-form",
                  },
        [isEligible]
    );

    const [patientConfirm, setPatientConfirm] = React.useState(null);
    const [consent, setConsent] = React.useState(patient.savedConsent);
    const [lasScreen, setLastScreen] = React.useState(false);
    const [allowLastStep, setAllowLastStep] = React.useState(false);
    const [newPatient, setNewPatient] = React.useState();
    const onDeleteRef = React.useRef(true);

    const redirectToPatients = React.useCallback(() => {
        navigate("/patients-potentiels");
    }, []);

    const redirectToCreation = React.useCallback(() => {
        navigate(`/patients-potentiels/${patient.id}/nouveau-patient`);
    });

    const onUploadConcent = (consent) =>
        API.uploadFile(consent)
            .then((item) => setConsent(item["@id"]))
            .catch(() => {
                setConsent(null);
                toast.error("L'upload a échoué pour une raison inconnue");
            });

    const onSubmitCallback = React.useCallback(
        (form) => {
            onDeleteRef.current = false;
            onSave(form)
                .then((res) => {
                    setNewPatient(res);
                    setLastScreen(true);
                })
                .catch(() => {
                    toast.error("Une erreur s'est produite lors de la finalisation du profil du nouveau patient potentiel.");
                });
        },
        [onSave]
    );

    React.useEffect(
        () => () => {
            if (onDeleteRef.current) {
                onDelete();
            }
        },
        [patient]
    );

    const submitLastForm = React.useCallback(
        (form) => {
            if (form.question === "true") {
                const data = {};
                data.email = newPatient.email;
                data.practitioner = newPatient.practitioner["@id"];
                data.pointOfContact = newPatient.pointOfContact === null ? null : newPatient.pointOfContact["@id"];
                data.identifiedBy = newPatient.identifiedBy === null ? null : newPatient.identifiedBy["@id"];
                data.phoneNumber = newPatient.phoneNumber;
                data.consentPatientValidated = true;
                data.consentPractitionnerValidated = true;
                data.consentPatientValidationDate = moment().format("YYYY-MM-DD[T]HH:mm:ssZ");
                data.consentPractitionnerDate = moment().format("YYYY-MM-DD[T]HH:mm:ssZ");

                tryEditPotentialPatient(patient, data)
                    .then(() => redirectToCreation())
                    .catch(() => toast.error("Une erreur s'est produite lors de la finalisation du profil du nouveau patient potentiel."));
            }
            if (form.question === "false") {
                redirectToPatients();
            }
        },
        [newPatient]
    );

    return (
        <Animated animationIn="fadeIn">
            <div className="breakScoreQuestionnaire__container__step3">
                <div className="breakScoreQuestionnaire__container__step3--leftImage">
                    <img src={LeftImage} />
                </div>
                <div>
                    <div className="breakScoreQuestionnaire__container__step3--rightForm">
                        {lasScreen ? (
                            <section className="mt-4">
                                <Form onSubmit={submitLastForm} id="doctorNextStepForm">
                                    <p>
                                        Souhaitez-vous créer le compte du patient dès maintenant ? <br />
                                        <i>Cela implique d’avoir eu l’accord de son médecin traitant. </i>
                                    </p>
                                    <InputRadio options={eligibleOptions} name="question" onChange={() => setAllowLastStep(true)} />

                                    <div className="txtRight mt-4">
                                        <IconButton
                                            className="button__primaryButton"
                                            type="submit"
                                            icon="arrow-right"
                                            right
                                            disabled={allowLastStep ? false : true}
                                        >
                                            Enregistrer
                                        </IconButton>
                                    </div>
                                </Form>
                            </section>
                        ) : (
                            <div>
                                <div>
                                    <div className="form__group">
                                        <p className="title-4 mb-4">Au vu des réponses, le patient se situe {eligible.considered} du seuil d'éligibilité.</p>
                                    </div>
                                    <div style={{ width: "90%" }}>
                                        <Gauge
                                            value={patient.score > 100 ? 100 : Math.round(patient.score)}
                                            info="Score du patient"
                                            legend="Seuil d’éligibilité"
                                        />
                                    </div>
                                    <section className="mt-4">
                                        <p>Souhaitez-vous, en accord avec le patient, poursuivre son intégration dans le programme AProDia ?</p>
                                        <InputRadio
                                            options={eligibleOptions}
                                            onChange={(val) => setPatientConfirm(val === eligibleOptions[0].value.toString())}
                                        />
                                    </section>
                                    {patientConfirm && (
                                        <section className="mt-4">
                                            <p className="title-3">
                                                Merci de remplir les champs suivants pour que le patient soit recontacté par un facilitateur Timkl.
                                            </p>
                                            <Form onSubmit={onSubmitCallback} id={formId}>
                                                <div className="grid">
                                                    <InputSymbol
                                                        type="tel"
                                                        label="N° de téléphone du patient"
                                                        placeholder="→ Saisissez le numéro du patient"
                                                        required
                                                        name="phoneNumber"
                                                        inputClassName="col-sm-30 col-xs-100 not-floating-important"
                                                        symbol={<Icon name="phone" size={16} />}
                                                    />
                                                </div>
                                                <div className="grid mb-4">
                                                    <InputSymbol
                                                        type="email"
                                                        label="Adresse email du patient"
                                                        placeholder="→ Saisissez l’adresse email du patient"
                                                        name="email"
                                                        inputClassName="col-sm-50 col-xs-100 not-floating-important"
                                                        symbol={<Icon name="email" size={18} />}
                                                        required
                                                    />
                                                </div>
                                                <DoctorButton
                                                    name="practitioner"
                                                    searchAction={(params) => API.searchInRpps(params)}
                                                    icon="stethoscope"
                                                    secondary
                                                    buttonClassName="timkl__color--blue-important mb-4"
                                                    required
                                                />
                                                <PractitionerButton
                                                    name="pointOfContact"
                                                    searchAction={(params) => API.searchInRpps(params)}
                                                    icon="stethoscope"
                                                    secondary
                                                    labels={{
                                                        title: "Voulez-vous sélectionner ce référent de proximité",
                                                        buttonModal: "Identité du référent de proximité (facultatif)",
                                                        buttonSave: "Sélectionner",
                                                    }}
                                                    buttonClassName="timkl__color--blue-important mb-4 "
                                                />
                                                <PractitionerButton
                                                    name="identifiedBy"
                                                    searchAction={(params) => API.searchInRpps(params)}
                                                    icon="stethoscope"
                                                    secondary
                                                    labels={{
                                                        title: "Quel est le PdS qui a identifié ce patient ? ",
                                                        buttonModal: "Quel est le PdS qui a identifié ce patient ? ",
                                                        buttonSave: "Sélectionner",
                                                    }}
                                                    buttonClassName="timkl__color--blue-important mb-4 "
                                                />

                                                <ButtonUpload required={true} uploadAction={onUploadConcent} icon="document-import" className="mb-4">
                                                    Charger un consentement signé
                                                </ButtonUpload>
                                            </Form>
                                        </section>
                                    )}
                                </div>
                                <StepButtons
                                    prevStep={prevStep}
                                    isLoadingNext={isLoading}
                                    prevButtonClassName="text-uppercase"
                                    nextButtonClassName={""}
                                    isLast
                                    lastIsLeft
                                    validIcon={eligible.buttonIcon}
                                    validText={eligible.button}
                                    secondary={!isEligible}
                                    form={formId}
                                    onClickNext={patientConfirm ? null : redirectToPatients}
                                    disabledNext={consent === null}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Animated>
    );
};

Step3.propTypes = {
    prevStep: PropTypes.func.isRequired,
    isEligible: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    accessToken: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    patient: PropTypes.object.isRequired,
    tryEditPotentialPatient: PropTypes.func.isRequired,
};

export default Step3;
