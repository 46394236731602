import React from "react";

import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import PrivateRoute from "./components/Utils/PrivateRoute";

import LoginScreen from "./screens/Login/LoginScreen";
import RequestPasswordScreen from "./screens/Login/RequestPasswordScreen";
import LogoutScreen from "./screens/Login/LogoutScreen";
import ScrollToTop from "./components/Utils/ScrollToTop";
import ResetPasswordScreen from "./screens/Email/ResetPasswordScreen";
import NotFoundExceptionScreen from "./screens/Exceptions/NotFoundException";
import DashboardScreen from "./screens/Dashboard/DashboardScreen";
import RecommendationsScreen from "./screens/Recommendations/RecommendationsScreen";
import RecommendationsAddScreen from "./screens/Recommendations/RecommendationsAddScreen";
import RecommendationsEditScreen from "./screens/Recommendations/RecommendationsEditScreen";
import TrackingBillingScreen from "./screens/TrackingBilling/TrackingBillingScreen";

import SettingsScreen from "./screens/Settings/SettingsScreen";
import {
    AddAdminScreen,
    AddBreakScoreQuestionScreen,
    AddBreakScoreNoInsulinQuestionScreen,
    AddComplicationScreen,
    AddCoordinatorScreen,
    AddModeratorScreen,
    AddPartnerScreen,
    AddPointOfAttentionScreen,
    AddStudyQuestionScreen,
    AddTaskLabelScreen,
    AddTreatmentGroupScreen,
    AddDiagnosticQuestionScreen,
    AddThematicsScreen,
} from "./screens/Settings/AddScreens";
import {
    EditAdminScreen,
    EditBreakScoreQuestionScreen,
    EditBreakScoreNoInsulinQuestionScreen,
    EditComplicationScreen,
    EditCoordinatorScreen,
    EditModeratorScreen,
    EditPartnerScreen,
    EditPointOfAttentionScreen,
    EditStudyQuestionScreen,
    EditTaskLabelScreen,
    EditTreatmentGroupScreen,
    EditDiagnosticQuestionScreen,
} from "./screens/Settings/EditScreens";

import ProfileScreen from "./screens/Profile/ProfileScreen";
import MedicalsScreen from "./screens/Moderation/MedicalsScreen";
import CoordinatorScreen from "./screens/Coordinator/CoordinatorScreen";
import CoordinatorPatientBreakScoreScreen from "./screens/Coordinator/CoordinatorPatientBreakScoreScreen";
import CoordinatorPatientInformationsScreen from "./screens/Coordinator/CoordinatorPatientInformationsScreen";
import CoordinatorPatientDashboardScreen from "./screens/Coordinator/CoordinatorPatientDashboardScreen";
import CoordinatorPatientLastDiagnosticScreen from "./screens/Coordinator/CoordinatorPatientLastDiagnosticScreen";
import CoordinatorPatientCoordonneesScreen from "./screens/Coordinator/CoordinatorPatientCoordonneesScreen";
import CoordinatorPatientNotesScreen from "./screens/Coordinator/CoordinatorPatientNotesScreen";
import CoordinatorPatientReportsScreen from "./screens/Coordinator/CoordinatorPatientReportsScreen";
import CoordinatorPatientConsultsScreen from "./screens/Coordinator/CoordinatorPatientConsultsScreen";
import CoordinatorPatientExamsScreen from "./screens/Coordinator/CoordinatorPatientExamsScreen";
import CoordinatorPatientProSanteScreen from "./screens/Coordinator/CoordinatorPatientProSanteScreen";
import CoordinatorPatientDataScreen from "./screens/Coordinator/CoordinatorPatientDataScreen";
import PotentialPatientsScreen from "./screens/PotentialPatients/PotentialPatients";
import CoordinatorPatientTasksScreen from "./screens/Coordinator/CoordinatorPatientTasksScreen";
import PatientsAddScreen from "./screens/Patients/PatientsAddScreen";
import BreakScoreQuestionnaireScreen from "./screens/BreakScoreQuestionnaireScreen";
import EditThematicScreen from "./screens/Settings/EditScreens/EditThematic";
import CoordinatorPatientsDiagnosticScreen from "./screens/Coordinator/PatientsDiagnosticScreen/index";
import CoordinatorPatientBeckScreen from "./screens/Coordinator/CoordinatorPatientBeckScreen";
import CoordinatorPatientEpicesScreen from "./screens/Coordinator/CoordinatorPatientEpicesScreen";

import RedirectToPatientDashboard from "./utils/redirectToPatientDashboard";

const App = () => (
    <ScrollToTop>
        <ToastContainer autoClose={3000} />
        <Routes>
            <Route path="/" element={<Navigate to="/connexion" replace />} />
            <Route path="/connexion" element={<LoginScreen />} />
            <Route path="/mot-de-passe-oublie" element={<RequestPasswordScreen />} />
            <Route path="/email/new-password" element={<ResetPasswordScreen />} />
            <Route path="/deconnexion" element={<LogoutScreen />} />

            <Route element={<PrivateRoute allowedRoles={["ROLE_ADMIN"]} />}>
                <Route path="/dashboard" element={<DashboardScreen />} />
                <Route path="/suivi-facturation" element={<TrackingBillingScreen />} />

                <Route path="/recommandations" element={<RecommendationsScreen />} />
                <Route path="/recommandations/ajouter" element={<RecommendationsAddScreen />} />
                <Route path="/recommandations/:id/modifier" element={<RecommendationsEditScreen />} />

                <Route path="/configuration" element={<SettingsScreen />} />
                <Route path="/configuration/ajouter-traitement" element={<AddTreatmentGroupScreen />} />
                <Route path="/configuration/modifier-traitement/:id" element={<EditTreatmentGroupScreen />} />
                <Route path="/configuration/ajouter-action" element={<AddTaskLabelScreen />} />
                <Route path="/configuration/modifier-action/:id" element={<EditTaskLabelScreen />} />
                <Route path="/configuration/ajouter-administrateur" element={<AddAdminScreen />} />
                <Route path="/configuration/modifier-administrateur/:id" element={<EditAdminScreen />} />
                <Route path="/configuration/ajouter-moderateur" element={<AddModeratorScreen />} />
                <Route path="/configuration/modifier-moderateur/:id" element={<EditModeratorScreen />} />
                <Route path="/configuration/ajouter-partenaire" element={<AddPartnerScreen />} />
                <Route path="/configuration/modifier-partenaire/:id" element={<EditPartnerScreen />} />
                <Route path="/configuration/ajouter-point-de-vigilance" element={<AddPointOfAttentionScreen />} />
                <Route path="/configuration/modifier-point-de-vigilance/:id" element={<EditPointOfAttentionScreen />} />
                <Route path="/configuration/ajouter-thematique" element={<AddThematicsScreen />} />
                <Route path="/configuration/modifier-thematique/:id" element={<EditThematicScreen />} />
                <Route path="/configuration/ajouter-facilitateur" element={<AddCoordinatorScreen />} />
                <Route path="/configuration/modifier-facilitateur/:id" element={<EditCoordinatorScreen />} />
                <Route path="/configuration/ajouter-complication" element={<AddComplicationScreen />} />
                <Route path="/configuration/modifier-complication/:id" element={<EditComplicationScreen />} />
                <Route path="/configuration/ajouter-question-etude" element={<AddStudyQuestionScreen />} />
                <Route path="/configuration/modifier-question-etude/:id" element={<EditStudyQuestionScreen />} />
                <Route path="/configuration/ajouter-question-rupture" element={<AddBreakScoreQuestionScreen />} />
                <Route path="/configuration/modifier-question-rupture/:id" element={<EditBreakScoreQuestionScreen />} />
                <Route path="/configuration/ajouter-question-rupture-non-insulinotraite" element={<AddBreakScoreNoInsulinQuestionScreen />} />
                <Route path="/configuration/modifier-question-rupture-non-insulinotraite/:id" element={<EditBreakScoreNoInsulinQuestionScreen />} />
                <Route path="/configuration/ajouter-question-information" element={<AddDiagnosticQuestionScreen />} />
                <Route path="/configuration/modifier-question-information/:id" element={<EditDiagnosticQuestionScreen />} />
            </Route>

            <Route element={<PrivateRoute allowedRoles={["ROLE_MODERATOR"]} />}>
                <Route path="/moderation" element={<MedicalsScreen />} />
            </Route>

            <Route element={<PrivateRoute allowedRoles={["ROLE_COORDINATOR"]} />}>
                <Route path="/facilitateurs" element={<CoordinatorScreen />} />
                <Route path="/facilitateurs/patient/:id" element={<RedirectToPatientDashboard />} />
                <Route path="/facilitateurs/patient/:id/dashboard" element={<CoordinatorPatientDashboardScreen />} />
                <Route path="/facilitateurs/patient/:id/donnees" element={<CoordinatorPatientDataScreen />} />
                <Route path="/facilitateurs/patient/:id/examens" element={<CoordinatorPatientExamsScreen />} />
                <Route path="/facilitateurs/patient/:id/visites" element={<CoordinatorPatientConsultsScreen />} />
                <Route path="/facilitateurs/patient/:id/informations" element={<CoordinatorPatientInformationsScreen />} />
                <Route path="/facilitateurs/patient/:id/derniere-consultation" element={<CoordinatorPatientLastDiagnosticScreen />} />
                <Route path="/facilitateurs/patient/:id/coordonnees" element={<CoordinatorPatientCoordonneesScreen />} />
                <Route path="/facilitateurs/patient/:id/notes" element={<CoordinatorPatientNotesScreen />} />
                <Route path="/facilitateurs/patient/:id/comptes-rendus" element={<CoordinatorPatientReportsScreen />} />
                <Route path="/facilitateurs/patient/:id/medecins" element={<CoordinatorPatientProSanteScreen />} />
                <Route path="/facilitateurs/patient/:id/actions" element={<CoordinatorPatientTasksScreen />} />
                <Route path="/facilitateurs/patient/:id/break-score" element={<CoordinatorPatientBreakScoreScreen />} />
                <Route path="/facilitateurs/patient/:id/nouvelle-consultation" element={<CoordinatorPatientsDiagnosticScreen />} />
                <Route path="/facilitateurs/patient/:id/questionnaire-beck" element={<CoordinatorPatientBeckScreen />} />
                <Route path="/facilitateurs/patient/:id/questionnaire-epices" element={<CoordinatorPatientEpicesScreen />} />

                <Route path="/patients-potentiels" element={<PotentialPatientsScreen />} />
                <Route path="/patients-potentiels/:id/nouveau-patient" element={<PatientsAddScreen />} />
            </Route>

            <Route element={<PrivateRoute allowedRoles={["ROLE_ADMIN", "ROLE_MODERATOR", "ROLE_COORDINATOR"]} />}>
                <Route path="/mon-profil" element={<ProfileScreen />} />
                <Route path="/questionnaire-rupture" element={<BreakScoreQuestionnaireScreen />} />
            </Route>

            <Route path="*" element={<NotFoundExceptionScreen />} />
        </Routes>
    </ScrollToTop>
);

export default App;
