import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { connect } from "react-redux";
import { tryCreateNote, tryFetchSinglePatientNotes } from "../../actions/notes";
import { tryFetchPartners } from "../../actions/partners";

import { Icon, AddButtonModal, DefaultLoader } from "@rdcs/dap-front-library";

import CoordinatorPatientLayout from "../../components/Coordinator/CoordinatorPatientLayout";
import NoteItem from "../../components/Coordinator/Notes/NoteItem";
import PatientNoteForm from "../../components/Coordinator/Notes/PatientNoteForm";
import { getViolations } from "../../utils/utils";

const CoordinatorPatientNotesScreen = ({ auth, notes, partners, tryCreateNote, tryFetchSinglePatientNotes, tryFetchPartners }) => {
    const { id } = useParams();
    const patientId = React.useMemo(() => parseInt(id), [id]);

    const patientNotes = React.useMemo(
        () =>
            notes.list
                .filter((note) => note.active === true)
                .sort((a, b) => {
                    if (a.updatedAt && b.updatedAt) {
                        return new Date(b.updatedAt) - new Date(a.updatedAt);
                    } else {
                        return new Date(b.createdAt) - new Date(a.createdAt);
                    }
                }),
        [notes.list]
    );

    React.useEffect(() => {
        document.title = "Timkl - Mes Patients";

        tryFetchSinglePatientNotes(auth, patientId);
        tryFetchPartners(auth);
    }, []);
    return (
        <CoordinatorPatientLayout activeNav="comptes-rendus">
            {notes.listLoading ? (
                <DefaultLoader color="#CCC" />
            ) : (
                <div>
                    <div className="dashboardContent__element">
                        <div>
                            {patientNotes.length === 0 ? (
                                <p>Aucune note pour ce patient actuellement.</p>
                            ) : (
                                <div className="reportsList grid grid__padding-10">
                                    {patientNotes.map((note, index) => (
                                        <div className="col-md-50 col-sm-100" key={note.id}>
                                            <NoteItem key={index} patientId={patientId} note={note} partners={partners} />
                                        </div>
                                    ))}
                                </div>
                            )}
                            <div>
                                {partners.length ? (
                                    <AddButtonModal
                                        iconName="add"
                                        iconSize={30}
                                        title="Ajouter une note d'un partenaire"
                                        className="reportsListItem__add-text primary-color-important reportsListItem__add mr-2"
                                    >
                                        {(modalRef) => (
                                            <PatientNoteForm
                                                patientId={patientId}
                                                onSubmit={(form) => tryCreateNote(auth, form).then(() => modalRef.current?.toggleModal())}
                                                isLoading={notes.createLoading}
                                                errors={getViolations(notes.createError)}
                                                partners={partners}
                                            />
                                        )}
                                    </AddButtonModal>
                                ) : null}
                                <AddButtonModal
                                    iconName="add"
                                    iconSize={30}
                                    title="Ajouter une note"
                                    className="reportsListItem__add-text primary-color-important reportsListItem__add"
                                >
                                    {(modalRef) => (
                                        <PatientNoteForm
                                            patientId={patientId}
                                            onSubmit={(form) => tryCreateNote(auth, form).then(() => modalRef.current?.toggleModal())}
                                            isLoading={notes.createLoading}
                                            errors={getViolations(notes.createError)}
                                        />
                                    )}
                                </AddButtonModal>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </CoordinatorPatientLayout>
    );
};

CoordinatorPatientNotesScreen.propTypes = {
    auth: PropTypes.object.isRequired,
    notes: PropTypes.object.isRequired,
    partners: PropTypes.arrayOf(PropTypes.object).isRequired,
    tryCreateNote: PropTypes.func.isRequired,
    tryFetchSinglePatientNotes: PropTypes.func.isRequired,
    tryFetchPartners: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, notes, partners: { list: partners } }) => ({
    auth,
    notes,
    partners,
});

const mapDispatchToProps = {
    tryCreateNote,
    tryFetchSinglePatientNotes,
    tryFetchPartners,
};

export default connect(mapStateToProps, mapDispatchToProps)(CoordinatorPatientNotesScreen);
