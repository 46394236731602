import React from "react";
import PropTypes from "prop-types";
import { useParams, useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import { tryFetchComplications } from "../../../actions/complications";
import { tryFetchTreatmentGroups } from "../../../actions/treatments";
import Questions from "../../../actions/questions";
import {
    tryCreateDiagnosis,
    tryEditDiagnosis,
    tryFetchCheckUpQuestions,
    tryFetchDiagnosisRecos,
    trySetDiagnosisRecommendationAvailability,
} from "../../../actions/diagnosis";

import { tryFetchLastDiagnosis, trySinglePatient } from "../../../actions/patients";

import moment from "moment";
import { DefaultLoader, PractitionerDiagnosticHeader, PageTitle, useDidUpdate, getViolations } from "@rdcs/dap-front-library";

import AppLayout from "../../../components/Layout/AppLayout";
import DiagnosisStep1 from "../../../components/Diagnosis/DiagnosisStep1";
import DiagnosisStep2 from "../../../components/Diagnosis/DiagnosisStep2";
import DiagnosisStep3 from "../../../components/Diagnosis/DiagnosisStep3";
import DiagnosisStep4 from "../../../components/Diagnosis/DiagnosisStep4";

import useState, { steps } from "./useState";
import { tryCreateQuestionnairePatient, tryQuestionnaireId } from "../../../actions/questionnaire";

const { tryFetchDiagnosticQuestions } = Questions;

const CoordinatorPatientsDiagnosticScreen = ({
    user,
    auth,
    patients,
    complications,
    treatments,
    diagnosis,
    questions,
    trySinglePatient,
    tryCreateDiagnosis,
    tryEditDiagnosis,
    tryFetchComplications,
    tryFetchTreatmentGroups,
    tryFetchLastDiagnosis,
    tryFetchDiagnosisRecos,
    tryFetchCheckUpQuestions,
    trySetDiagnosisRecommendationAvailability,
    tryFetchDiagnosticQuestions,
    tryCreateQuestionnairePatient,
    tryQuestionnaireId,
}) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const patientId = React.useMemo(() => parseInt(id), [id]);
    const formattedPatientId = `/patients/${patientId}`;
    const breadcrumbs = React.useMemo(
        () => [
            { label: "Facilitateurs", link: "/facilitateurs" },
            { label: "Fiche patient", link: `/facilitateurs/patient/${id}/dashboard` },
            { label: "Revoir le parcours de soin" },
        ],
        [id]
    );

    const [
        { medicalPatient, isNextStep, currentStep, dataLoading, form, stepForm, checkUpQuestions, checkUpQuestionsForceEnabled },
        { setMedicalPatient, init, setFormValue, prevStep, setNextStep, errorStep, setStep1AndNextStep, nextStep, setGoalAndNextStep },
    ] = useState();

    React.useEffect(() => {
        setMedicalPatient(patients.singlePatient);
        Promise.all([
            tryFetchCheckUpQuestions(),
            trySinglePatient(patientId),
            tryFetchLastDiagnosis(patientId),
            tryFetchComplications(auth),
            tryFetchTreatmentGroups(auth),
            tryFetchDiagnosticQuestions(true),
        ])
            .then(([checkUpQuestionsInit, patient, patientLastDiagnosis]) => {
                if (patientLastDiagnosis.questionnaire) {
                    const questionnaireId = patientLastDiagnosis.questionnaire.split("questionnaires/")[1];
                    tryQuestionnaireId({ type: "diagnostic", id: questionnaireId }).then((diagnosticAnswers) => {
                        init(checkUpQuestionsInit, patient, patientLastDiagnosis, diagnosticAnswers.answers);
                    });
                } else {
                    init(checkUpQuestionsInit, patient, patientLastDiagnosis, []);
                }
            })
            .catch(console.error);
    }, []);

    useDidUpdate(async () => {
        if (isNextStep) {
            switch (currentStep) {
                case 1: {
                    const answers = [];
                    const checkUpAnswers = [];
                    const checkUpQuestionsFormPrefix = "CheckUpQuestionsForm#";

                    for (const i in questions.diagnosticQuestions) {
                        const question = questions.diagnosticQuestions[i];
                        let answer = null;
                        const answerLabel = `diagnosticAnswers${i}`;
                        const otherLabel = `${answerLabel}-other`;

                        if (stepForm[answerLabel] && stepForm[answerLabel].length > 0) {
                            answer = Array.isArray(stepForm[answerLabel]) ? [...stepForm[answerLabel]] : [stepForm[answerLabel]];
                        }
                        if (stepForm[otherLabel]) {
                            answer.push(stepForm[otherLabel]);
                        }
                        if (answer) {
                            answers[i] = {
                                question: question.label,
                                values: answer,
                                fieldType: question.fieldType,
                                fieldLength: question.fieldLength,
                                mandatory: question.mandatory,
                            };
                        }
                    }
                    for (const stepFormField in stepForm) {
                        if (stepFormField.startsWith(checkUpQuestionsFormPrefix)) {
                            checkUpAnswers.push({
                                question: stepFormField.substring(checkUpQuestionsFormPrefix.length),
                                value: stepForm[stepFormField],
                            });
                        }
                    }
                    for (const checkUpQuestion of checkUpQuestions) {
                        if (!checkUpAnswers.find(({ question }) => question === checkUpQuestion["@id"])) {
                            checkUpAnswers.push({
                                question: checkUpQuestion["@id"],
                                value: null,
                            });
                        }
                    }
                    const diagnosticQuestionnaireAnswers = answers.map(({ question, values }) => {
                        return {
                            values,
                            question,
                        };
                    });

                    const diagnosticQuestionnaireData = {
                        patient: formattedPatientId,
                        type: "diagnostic",
                        answers: diagnosticQuestionnaireAnswers.filter((answer) => answer.question),
                    };
                    const response = await tryCreateQuestionnairePatient(diagnosticQuestionnaireData);
                    const questionnaireId = response["@id"];
                    setStep1AndNextStep(stepForm.pathology, stepForm.diabetesDiscoveryYear, stepForm.treatmentGroup, answers, checkUpAnswers, questionnaireId);
                    break;
                }

                case 2:
                    tryCreateDiagnosis({
                        patient: {
                            ...form.patient,
                            id: formattedPatientId,
                            checkUpAnswers: form.hasLastDiagnosis
                                ? []
                                : form.patient.checkUpAnswers // Do not resubmit checkUpAnswers
                                      .map(
                                          (
                                              checkUpAnswer // Set answer false if null
                                          ) => (checkUpAnswer.value === null ? { ...checkUpAnswer, value: false } : checkUpAnswer)
                                      ),
                        },
                        pathology: form.pathology,
                        treatmentGroup: form.treatmentGroup,
                        complicationInstances: form.complicationInstances,
                        consultationAnswers: form.consultationAnswers,
                        diagnosticAnswers: form.diagnosticAnswers && Object.values(form.diagnosticAnswers),
                        questionnaire: form.questionnaire,
                    })
                        .then((diagnosis) => {
                            if (patients.singlePatient.isDelegate === false) {
                                navigate(`/facilitateurs/patient/${patientId}/dashboard`);
                            } else {
                                setGoalAndNextStep(diagnosis.goal);
                            }
                        })
                        .catch(errorStep);
                    break;

                case 3:
                    tryEditDiagnosis(diagnosis.patientUncompletedDiagnosis.id, {
                        goal: parseFloat(stepForm.goal),
                        goalJustification: stepForm.goalJustification,
                        bsMax: form.bsMax,
                        bsMin: form.bsMin,
                    })
                        .then((diagnosis) => tryFetchDiagnosisRecos(diagnosis, null))
                        .then(setNextStep)
                        .catch(errorStep);
                    break;

                case 4: {
                    const diagnosisRecommendationAvailabilityRequests = [];

                    for (const { id, active } of diagnosis.recos) {
                        const recoId = id.toString();

                        if (recoId in stepForm && stepForm[recoId] !== active) {
                            diagnosisRecommendationAvailabilityRequests.push(trySetDiagnosisRecommendationAvailability(id, stepForm[recoId]));
                        }
                    }

                    Promise.all(diagnosisRecommendationAvailabilityRequests)
                        .then(() => tryEditDiagnosis(diagnosis.patientUncompletedDiagnosis.id, { completed: true }))
                        .then(() => navigate(`/facilitateurs/patient/${patientId}/dashboard`))
                        .catch(errorStep);
                    break;
                }
            }
        }
    }, [isNextStep]);

    const diagnosisCreateError = React.useMemo(() => getViolations(diagnosis.diagnosisCreateError), [diagnosis.diagnosisCreateError]);
    const diagnosisEditError = React.useMemo(() => getViolations(diagnosis.diagnosisEditError), [diagnosis.diagnosisEditError]);

    const DiagnosisStep = React.useMemo(() => {
        if (dataLoading === false) {
            switch (currentStep) {
                case 1:
                    return (
                        <DiagnosisStep1
                            diagnosisQuestions={questions.diagnosticQuestions}
                            treatmentGroups={treatments.treatmentGroups}
                            checkUpQuestions={checkUpQuestions.filter((q) => {
                                switch (true) {
                                    case q.label === "Consultation d'un Pédiatre" && moment().diff(moment(patients.singlePatient.dateOfBirth), "years") > 15:
                                        return false;
                                    case q.label === "Consultation d'un Endocrino-diabétologue" && form.pathology !== "DT1":
                                        return false;
                                }
                                return true;
                            })}
                            defaultValues={form}
                            checkUpQuestionsForceEnabled={checkUpQuestionsForceEnabled}
                            nextStep={nextStep}
                            prevStep={prevStep}
                        />
                    );
                case 2:
                    return (
                        <DiagnosisStep2
                            complications={complications.list}
                            defaultValues={form}
                            setFormValue={setFormValue}
                            nextStep={nextStep}
                            prevStep={prevStep}
                            isLoading={diagnosis.diagnosisCreateLoading || diagnosis.recosLoading}
                            errors={diagnosisCreateError}
                            isDelegate={patients.singlePatient.isDelegate}
                        />
                    );
                case 3:
                    return (
                        <DiagnosisStep3
                            goal={form.goal}
                            nextStep={nextStep}
                            prevStep={prevStep}
                            isLoading={diagnosis.diagnosisEditLoading || diagnosis.recosLoading}
                            errors={diagnosisEditError}
                        />
                    );
                case 4:
                    return (
                        <DiagnosisStep4
                            recos={diagnosis.recos}
                            patient={patients.singlePatient}
                            hcp={user}
                            nextStep={nextStep}
                            prevStep={prevStep}
                            isLoading={diagnosis.diagnosisEditLoading}
                            changeDiagnosisVal={trySetDiagnosisRecommendationAvailability}
                        />
                    );
                default:
                    return undefined;
            }
        }
        return <DefaultLoader />;
    }, [
        dataLoading,
        currentStep,
        checkUpQuestions,
        form?.pathology,
        diagnosis.diagnosisCreateLoading,
        diagnosis.diagnosisEditLoading,
        diagnosis.recosLoading,
        diagnosisCreateError,
        diagnosisEditError,
    ]);
    return (
        <PageTitle title="Timkl - Nouvelle consultation">
            <AppLayout navActive={0} user={user} pageClass="page-dashboard" breadcrumbs={breadcrumbs}>
                {medicalPatient && <PractitionerDiagnosticHeader patient={medicalPatient} steps={steps} index={currentStep - 1} />}

                <main className="diagnostic__main">
                    <div className="diagnostic__mainContainer">{DiagnosisStep}</div>
                </main>
            </AppLayout>
        </PageTitle>
    );
};

CoordinatorPatientsDiagnosticScreen.propTypes = {
    auth: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    patients: PropTypes.object.isRequired,
    complications: PropTypes.object.isRequired,
    treatments: PropTypes.object.isRequired,
    diagnosis: PropTypes.object.isRequired,
    questions: PropTypes.object.isRequired,
    trySinglePatient: PropTypes.func.isRequired,
    tryCreateDiagnosis: PropTypes.func.isRequired,
    tryEditDiagnosis: PropTypes.func.isRequired,
    //tryMedicalPatient: PropTypes.func.isRequired,
    tryFetchComplications: PropTypes.func.isRequired,
    tryFetchTreatmentGroups: PropTypes.func.isRequired,
    tryFetchLastDiagnosis: PropTypes.func.isRequired,
    tryFetchDiagnosisRecos: PropTypes.func.isRequired,
    tryFetchCheckUpQuestions: PropTypes.func.isRequired,
    trySetDiagnosisRecommendationAvailability: PropTypes.func.isRequired,
    tryFetchDiagnosticQuestions: PropTypes.func.isRequired,
    tryCreateQuestionnairePatient: PropTypes.func.isRequired,
    tryQuestionnaireId: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, patients, complications, treatments, diagnosis, questions }) => ({
    auth,
    user: auth.user,
    patients,
    complications,
    treatments,
    diagnosis,
    questions,
});

const mapDispatchToProps = {
    trySinglePatient,
    tryCreateDiagnosis,
    tryEditDiagnosis,
    tryFetchComplications,
    tryFetchTreatmentGroups,
    tryFetchLastDiagnosis,
    tryFetchDiagnosisRecos,
    tryFetchCheckUpQuestions,
    trySetDiagnosisRecommendationAvailability,
    tryFetchDiagnosticQuestions,
    tryCreateQuestionnairePatient,
    tryQuestionnaireId,
};

export default connect(mapStateToProps, mapDispatchToProps)(CoordinatorPatientsDiagnosticScreen);
