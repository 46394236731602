import React, { Fragment } from "react";

import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";

import { Button, InputText, InputNumber, Icon, Select } from "@rdcs/dap-front-library";

const NUMBER_RESPONSE = { min: 2, max: 20 };

const StudyQuestionForm = ({
    editedStudyQuestion: {
        fieldType: editedStudyQuestionType = "",
        label: editedStudyQuestionLabel = "",
        fieldLength: editedStudyQuestionLength = "short",
        responses: editedStudyQuestionResponse = new Array(NUMBER_RESPONSE.min).fill(""),
        minValue: editedStudyQuestionMinVal = 0,
        minLabel: editedStudyQuestionMinLabel = "",
        maxValue: editedStudyQuestionMaxVal = 20,
        maxLabel: editedStudyQuestionMaxLabel = "",
        forbiddenValue: editedStudyQuestionForbidden = null,
    } = {},
    errors,
    onSubmit,
    isLoading,
}) => {
    const location = useLocation();

    const [fieldType, setFieldType] = React.useState(editedStudyQuestionType);
    const [label, setLabel] = React.useState(editedStudyQuestionLabel);

    const [fieldLength, setFieldLength] = React.useState(editedStudyQuestionLength);

    const [responses, setResponses] = React.useState(editedStudyQuestionResponse);
    const [numberResponse, setNumberResponse] = React.useState(editedStudyQuestionResponse.length);

    const [minValue, setMinValue] = React.useState(editedStudyQuestionMinVal);
    const [minLabel, setMinLabel] = React.useState(editedStudyQuestionMinLabel);
    const [minError, setMinError] = React.useState(false);
    const [maxValue, setMaxValue] = React.useState(editedStudyQuestionMaxVal);
    const [maxLabel, setMaxLabel] = React.useState(editedStudyQuestionMaxLabel);
    const [maxError, setMaxError] = React.useState(false);
    const [forbiddenValue, setForbiddenValue] = React.useState(editedStudyQuestionForbidden);

    const onSubmitCallback = (event) => {
        const form = {
            label,
            fieldType,
            fieldLength,
            minValue,
            minLabel,
            maxValue,
            maxLabel,
            forbiddenValue,
        };
        let i = 0;

        event.preventDefault();
        while (i < numberResponse) {
            form[`answer${i + 1}`] = responses[i];
            ++i;
        }
        while (i < NUMBER_RESPONSE.max) {
            form[`answer${i + 1}`] = null;
            ++i;
        }
        onSubmit(form);
    };

    return (
        <form onSubmit={onSubmitCallback}>
            <div className="grid grid__padding-10">
                <div className="col-100">
                    <Select
                        label="Type de question"
                        defaultValue={fieldType}
                        options={[
                            { value: "", label: "Choisissez un type de question" },
                            { value: "text", label: "Question à réponse libre" },
                            { value: "choices", label: "Question à choix multiples" },
                            { value: "range", label: "Question à échelle" },
                        ]}
                        onChange={(val) => setFieldType(val)}
                        required
                    />
                </div>
                {fieldType && (
                    <div className="col-100">
                        <InputText
                            label="Question"
                            error={errors.name}
                            placeholder="Saisissez l'intitulé de votre question"
                            defaultValue={label}
                            required
                            onChange={setLabel}
                        />
                    </div>
                )}
                {fieldType === "text" && (
                    <div className="col-100">
                        <Select
                            label="Type de réponse"
                            defaultValue={fieldLength}
                            options={[
                                { value: "short", label: "Réponse courte (un mot)" },
                                { value: "long", label: "Réponse longue (une ou plusieurs phrases)" },
                            ]}
                            onChange={(val) => setFieldLength(val)}
                            required
                        />
                    </div>
                )}
                {fieldType === "choices" && (
                    <Fragment>
                        <div className="col-100">
                            <Select
                                label="Nombre de réponses"
                                options={Array.from({ length: NUMBER_RESPONSE.max + 1 - NUMBER_RESPONSE.min }, (_, i) => ({
                                    label: "" + (i + NUMBER_RESPONSE.min),
                                    value: i + NUMBER_RESPONSE.min,
                                }))}
                                defaultValue={numberResponse}
                                onChange={setNumberResponse}
                            />
                        </div>
                        {Array.from({ length: numberResponse }, (_, i) => (
                            <div className="col-100" key={i}>
                                <InputText
                                    label={`Réponse ${i + 1}`}
                                    error={errors.name}
                                    placeholder={`Saisissez le contenu de la réponse ${i + 1}`}
                                    defaultValue={responses[i]}
                                    required
                                    onChange={(val) => {
                                        const responsesTmp = responses.slice();

                                        responsesTmp[i] = val;
                                        setResponses(responsesTmp);
                                    }}
                                />
                            </div>
                        ))}
                    </Fragment>
                )}
                {fieldType === "range" && (
                    <Fragment>
                        <div className="col-20">
                            <InputNumber
                                label="Valeur min"
                                placeholder="Saisissez la valeur minimum de l'échelle"
                                defaultValue={minValue}
                                required
                                onChange={(val) => {
                                    val && (val < 0 || val > 19)
                                        ? setMinError("La valeur minimum doit être comprise entre 0 et 19")
                                        : val && val >= maxValue
                                        ? setMinError("La valeur minimum doit être inférieure à la valeur maximale")
                                        : (setMinError(false), setMinValue(parseInt(val)));
                                }}
                                min={0}
                                max={19}
                                step={1}
                                error={minError}
                            />
                        </div>
                        <div className="col-80">
                            <InputText
                                label="Label valeur min"
                                placeholder="Saisissez le texte de la valeur minimum de l'échelle"
                                defaultValue={minLabel}
                                required
                                onChange={setMinLabel}
                            />
                        </div>
                        <div className="col-20">
                            <InputNumber
                                label="Valeur max"
                                placeholder="Saisissez la valeur maximale de l'échelle"
                                defaultValue={maxValue}
                                required
                                onChange={(val) => {
                                    val && (val < 1 || val > 20)
                                        ? setMaxError("La valeur maximale doit être comprise entre 1 et 20")
                                        : val && val <= minValue
                                        ? setMaxError("La valeur maximale doit être supérieure à la valeur minimale")
                                        : (setMaxError(false), setMaxValue(parseInt(val)));
                                }}
                                min={1}
                                max={20}
                                step={1}
                                error={maxError}
                            />
                        </div>
                        <div className="col-80">
                            <InputText
                                label="Label valeur max"
                                placeholder="Saisissez le texte de la valeur maximale de l'échelle"
                                defaultValue={maxLabel}
                                required
                                onChange={setMaxLabel}
                            />
                        </div>
                        <div className="col-100">
                            <InputNumber
                                label="Valeur interdite"
                                placeholder="Saisissez la valeur interdite de l'échelle"
                                defaultValue={forbiddenValue}
                                onChange={(val) => setForbiddenValue(parseInt(val))}
                                min={0}
                                max={20}
                                step={1}
                            />
                        </div>
                    </Fragment>
                )}

                <div className="form__group col-100 text-center">
                    <Link
                        to={{
                            pathname: "/configuration",
                            state: { from: location },
                            tab: "study-questions",
                        }}
                    >
                        <Button className="button__secondaryButton">
                            <Icon name="arrow-left" className="mr-1" /> Retour
                        </Button>
                    </Link>
                    <Button type="submit" className="button__primaryButton" isLoading={isLoading}>
                        Enregistrer
                    </Button>
                </div>
            </div>
        </form>
    );
};

StudyQuestionForm.propTypes = {
    editedStudyQuestion: PropTypes.shape({
        label: PropTypes.string,
        responses: PropTypes.arrayOf(PropTypes.string.isRequired),
    }),
    onSubmit: PropTypes.func.isRequired,
    errors: PropTypes.shape({
        name: PropTypes.string,
    }).isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export default StudyQuestionForm;
