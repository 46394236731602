import React from "react";

import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";

import { Button, Form, InputText, Icon } from "@rdcs/dap-front-library";

const Thematic = ({ editedThematic: { name: editedPointOfAttentionName = "" } = {}, errors, onSubmit, isLoading }) => {
    const location = useLocation();

    const onSubmitCallback = (e) => {
        onSubmit({
            name: e.name,
        });
    };

    return (
        <Form className="form grid grid__padding-10" onSubmit={onSubmitCallback}>
            <div className="col-md-100">
                <InputText
                    label="Nom du point de la thématique"
                    placeholder="Saisissez le nom de la thématique"
                    name="name"
                    error={errors.name}
                    defaultValue={editedPointOfAttentionName}
                    required
                />
            </div>
            <div className="form__group col-100 text-center">
                <Link
                    to={{
                        pathname: "/configuration",
                        state: { from: location },
                        tab: "thematic",
                    }}
                >
                    <Button className="button__secondaryButton">
                        <Icon name="arrow-left" className="mr-1" /> Retour
                    </Button>
                </Link>
                <Button type="submit" className="button__primaryButton" isLoading={isLoading}>
                    Enregistrer
                </Button>
            </div>
        </Form>
    );
};

Thematic.propTypes = {
    editedThematic: PropTypes.shape({
        "@id": PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        parent: PropTypes.shape({ "@id": PropTypes.string }),
        name: PropTypes.string,
    }),
    errors: PropTypes.shape({
        name: PropTypes.string,
    }),
    onSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export default Thematic;
