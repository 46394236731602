import { createBrowserHistory } from "history";

let routerBaseName = "";

if (process.env.NODE_ENV === "production") {
}

const history = createBrowserHistory({
    forceRefresh: false,
    basename: routerBaseName,
});

export default history;
