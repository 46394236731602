import React from "react";
import { useNavigate } from "react-router-dom";

const useRedirectIfItemNotExist = (destination, item) => {
    const navigate = useNavigate();
    const [requestEnded, signalRequestEnded] = React.useState(false);

    React.useEffect(() => {
        if (requestEnded && !item) {
            navigate(destination);
        }
    }, [requestEnded, item, destination]);

    return React.useCallback(() => signalRequestEnded(true), [signalRequestEnded]);
};

export default useRedirectIfItemNotExist;
