import React from "react";
import PropTypes from "prop-types";

import { useParams } from "react-router-dom";

import { connect } from "react-redux";
import { tryFetchDiagnosticRecommendations } from "../../actions/patients";
import { tryCreatePatientAppointment, tryEditPatientAppointment, tryFetchPatientAppointments } from "../../actions/patients";
import { tryFetchPractitioners } from "../../actions/medicals";

import { DefaultLoader, Icon, AppointmentRealised, AppointmentRecommended, AppointmentScheduled } from "@rdcs/dap-front-library";
import moment from "moment";

import CoordinatorPatientLayout from "../../components/Coordinator/CoordinatorPatientLayout";

const CoordinatorPatientConsultsScreen = ({
    auth,
    patients,
    tryFetchPatientAppointments,
    tryCreatePatientAppointment,
    tryEditPatientAppointment,
    tryFetchPractitioners,
    tryFetchDiagnosticRecommendations,
}) => {
    const params = useParams();
    const patientId = React.useMemo(() => parseInt(params.id), [params.id]);

    React.useEffect(() => {
        document.title = "Timkl - Mes Patients";

        tryFetchPatientAppointments(patientId);
    }, []);

    const onPatientLoaded = React.useCallback((singlePatient) => {
        tryFetchDiagnosticRecommendations(auth, singlePatient);
    }, []);

    // Retrive all consultation's appointments.
    const appointments = React.useMemo(
        () => patients.patientAppointments.filter((item) => item.recommendations.find((r) => r.type === "consultation")),
        [patients.patientAppointments]
    );

    // Retrive all consultation's appointments planned.
    const appointmentsPlanned = React.useMemo(
        () =>
            appointments
                .filter((item) => item.doneAt === null)
                .sort((a, b) => new Date(a.appointment?.date || a.dueAt) - new Date(b.appointment?.date || b.dueAt)),
        [appointments]
    );

    // Retrive all consultation's appointments done.
    const appointmentsDone = React.useMemo(
        () => appointments.filter(({ doneAt }) => doneAt !== null).sort((a, b) => new Date(b.doneAt) - new Date(a.doneAt)),
        [appointments]
    );

    // Retrive all consultation's recommendations not planned.
    const recoConsults = React.useMemo(() => {
        const now = moment().unix();

        return (
            patients.diagnosticRecommendations
                // Keep consulations only
                .filter(({ recommendation: { type, id: _id } }) => {
                    if (type === "consultation") {
                        for (const appointment of appointments) {
                            if (appointment.recommendations.find((recommendation) => recommendation.id === _id)) {
                                return false;
                            }
                        }
                        return true;
                    }
                    return false;
                })
                // Bind Diagnostic recos with appointments
                .map((reco) => ({ ...reco, statusColor: now < moment(reco.dueAt).unix() ? "orange" : "red" }))
                // Remove all consultation done.
                .filter(({ appointment = null }) => !appointment?.doneAt)
                // Sort by color and after, sort by dueAt
                .sort((a, b) => {
                    const codeA = a.statusColor.charCodeAt(0);
                    const codeB = b.statusColor.charCodeAt(0);

                    if (codeA !== codeB) {
                        return codeB - codeA;
                    }
                    return new Date(a.appointment?.date || a.dueAt) - new Date(b.appointment?.date || b.dueAt);
                })
        );
    }, [patients.diagnosticRecommendations, appointments]);

    return (
        <CoordinatorPatientLayout activeNav="consults" onPatientLoaded={onPatientLoaded}>
            {patients.diagnosticRecommendationsLoading || patients.patientAppointmentsLoading ? (
                <DefaultLoader />
            ) : (
                <>
                    {/* Visites */}
                    <div className="dashboardContent__element">
                        <div className="dashboardContent__title">
                            <div className="dashboardContent__titleItem">
                                <div className="form__group d-flex align-items-center">
                                    <Icon name="stats" className="mr-1" size="30px" />
                                    <h1 className="title-1">Visites recommandées</h1>
                                </div>
                                <p className="txtSizeSmall text-gray mt-1 mb-2">Liste de toutes les visites recommandées pour le patient.</p>
                            </div>
                        </div>
                        <div className="consultsList">
                            {patients.singlePatientLoading || patients.diagnosticRecommendationsLoading ? (
                                <DefaultLoader />
                            ) : recoConsults && recoConsults.length > 0 ? (
                                recoConsults.map((recoInstance) => (
                                    <AppointmentRecommended
                                        key={recoInstance.id}
                                        dueAt={recoInstance.dueAt}
                                        statusColor={recoInstance.statusColor}
                                        recommendation={recoInstance.recommendation}
                                        showDescription={false}
                                        exams={patients.diagnosticRecommendations.filter(
                                            (r) => r.recommendation.consultationName === recoInstance.recommendation.title
                                        )}
                                        patientIri={`/patients/${patientId}`}
                                        searchPractitionerAction={(form) => tryFetchPractitioners(form)}
                                        createAppointmentLoading={patients.createAppointmentLoading}
                                        createAppointmentAction={(appointment) =>
                                            tryCreatePatientAppointment(appointment).then(() => tryFetchPatientAppointments(patientId))
                                        }
                                    />
                                ))
                            ) : (
                                <p>Aucune visite recommandée</p>
                            )}
                        </div>
                    </div>
                    {/* Visites planifiées */}
                    <div className="dashboardContent__element mt-4">
                        <div className="dashboardContent__title">
                            <div className="dashboardContent__titleItem">
                                <div className="form__group d-flex align-items-center">
                                    <Icon name="stats" size="30px" className="mr-1" />
                                    <h1 className="title-1 mb-2">Visites planifiées</h1>
                                </div>
                                <p className="txtSizeSmall text-gray mt-1 mb-2">Liste de toutes les visites planifiées.</p>
                            </div>
                        </div>
                        <div className="consultsList">
                            {patients.singlePatientLoading ? (
                                <DefaultLoader />
                            ) : appointmentsPlanned && appointmentsPlanned.length > 0 ? (
                                appointmentsPlanned.map((appointment) => (
                                    <>
                                        <AppointmentScheduled
                                            key={appointment.id}
                                            appointment={appointment}
                                            confirmAppointmentLoading={patients.editAppointmentLoading}
                                            confirmAppointmentAction={(form) => tryEditPatientAppointment(auth, form)}
                                        />
                                    </>
                                ))
                            ) : (
                                <p>Aucune visite planifiée</p>
                            )}
                        </div>
                    </div>
                    {/* Visites réalisées */}
                    <div className="dashboardContent__element mt-4">
                        <div className="dashboardContent__title">
                            <div className="dashboardContent__titleItem">
                                <div className=" form__group d-flex align-items-center">
                                    <Icon name="stats" size="30px" className="mr-1" />
                                    <h1 className="title-1">Visites réalisées</h1>
                                </div>
                                <p className="txtSizeSmall text-gray mt-1 mb-2">Toutes les visites réalisées par le patient</p>
                            </div>
                        </div>
                        <div className="consultsList">
                            {patients.diagnosticRecommendationsLoading ? (
                                <DefaultLoader />
                            ) : appointmentsDone && appointmentsDone.length > 0 ? (
                                appointmentsDone.map((appointment) => <AppointmentRealised key={appointment.id} appointment={appointment} />)
                            ) : (
                                <p>Aucune visite réalisée</p>
                            )}
                        </div>
                    </div>
                </>
            )}
        </CoordinatorPatientLayout>
    );
};

CoordinatorPatientConsultsScreen.propTypes = {
    auth: PropTypes.object.isRequired,
    patients: PropTypes.shape({
        diagnosticRecommendationsLoading: PropTypes.bool.isRequired,
        patientAppointmentsLoading: PropTypes.bool.isRequired,
        singlePatientLoading: PropTypes.bool.isRequired,
        createAppointmentLoading: PropTypes.bool.isRequired,
        editAppointmentLoading: PropTypes.bool.isRequired,
        singlePatient: PropTypes.object,
        patientAppointments: PropTypes.arrayOf(PropTypes.object).isRequired,
        diagnosticRecommendations: PropTypes.arrayOf(PropTypes.object).isRequired,
    }).isRequired,
    tryFetchPatientAppointments: PropTypes.func.isRequired,
    tryCreatePatientAppointment: PropTypes.func.isRequired,
    tryEditPatientAppointment: PropTypes.func.isRequired,
    tryFetchPractitioners: PropTypes.func.isRequired,
    tryFetchDiagnosticRecommendations: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    patients: state.patients,
});

const mapDispatchToProps = {
    tryFetchPatientAppointments,
    tryCreatePatientAppointment,
    tryEditPatientAppointment,
    tryFetchPractitioners,
    tryFetchDiagnosticRecommendations,
};

export default connect(mapStateToProps, mapDispatchToProps)(CoordinatorPatientConsultsScreen);
