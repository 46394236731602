import React from "react";
import PropTypes from "prop-types";
import { Link, useNavigate, useParams } from "react-router-dom";

import { connect } from "react-redux";

import { tryCreatePatient, tryFetchPotentialPatient, tryEditPotentialPatient } from "../../actions/patients";
import { tryGigyaSearchBySsn } from "../../actions/auth";

import { Animated } from "react-animated-css";
import moment from "moment";

import { InputNumber, Button, InputCheckbox, Form } from "@rdcs/dap-front-library";

import AppLayout from "../../components/Layout/AppLayout";
import PatientsAddForm from "../../components/Patients/PatientsAddForm";
import useAddPatient from "./useAddPatient";

const guessDateOfBirth = (gigyaProfile) =>
    gigyaProfile && gigyaProfile.birthDay && gigyaProfile.birthMonth && gigyaProfile.birthYear
        ? moment()
              .date(gigyaProfile.birthDay)
              .month(gigyaProfile.birthMonth - 1)
              .year(gigyaProfile.birthYear)
              .toDate()
        : null;

const breadcrumbs = [{ label: "Nouveaux patients", link: "/patients-potentiels" }, { label: "Ajouter un nouveau patient" }];

const PatientsAddScreen = ({ auth, patients, potentialPatient, tryCreatePatient, tryGigyaSearchBySsn, tryFetchPotentialPatient, tryEditPotentialPatient }) => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [{ searchFetched, searchSsn, disabled, patient }, dispatch] = useAddPatient();

    React.useEffect(() => {
        if (!(potentialPatient && potentialPatient.id === id)) {
            tryFetchPotentialPatient(auth, id);
        }

        document.title = "Timkl - Ajouter un patient";
    }, []);

    React.useEffect(() => {
        if (potentialPatient && (potentialPatient.consentPatientValidated !== true || potentialPatient.consentPractitionnerValidated !== true)) {
            navigate("/patients-potentiels");
        }
    }, [potentialPatient]);

    const guessSex = (gigyaProfile = null) => {
        const sexIndic = (gigyaProfile && gigyaProfile.gender) || searchSsn[0];

        switch (sexIndic) {
            case "m":
            case "1":
                return "male";
            case "f":
            case "2":
                return "female";
            default:
                return null;
        }
    };

    const search = () => {
        dispatch.startSearchFetched();
        tryGigyaSearchBySsn(auth, searchSsn)
            .then(() => {
                const {
                    searchBySsnResult: { gigya: gigyaProfile },
                } = auth;

                dispatch.fetchPatient(
                    gigyaProfile.firstName || potentialPatient.firstName,
                    gigyaProfile.lastName || potentialPatient.lastName,
                    gigyaProfile.email || potentialPatient.email || "",
                    guessDateOfBirth(gigyaProfile) || moment(potentialPatient.birthDate).toDate(),
                    guessSex(gigyaProfile),
                    Array.isArray(gigyaProfile.phones) && gigyaProfile.phones.length > 0 ? gigyaProfile.phones[0].number : potentialPatient.phoneNumber || "",
                    potentialPatient.alternativePhoneNumber || ""
                );
            })
            .catch(() => {
                dispatch.fetchPatient(
                    potentialPatient.firstName,
                    potentialPatient.lastName,
                    potentialPatient.email || "",
                    moment(potentialPatient.birthDate).toDate(),
                    guessSex(),
                    potentialPatient.phoneNumber || "",
                    potentialPatient.alternativePhoneNumber || ""
                );
            });
    };

    const onSubmit = (data) =>
        tryCreatePatient(auth, { ...data, potentialPatient: potentialPatient["@id"], zipcode: potentialPatient.zipcode })
            .then(() => {
                const idMatch = potentialPatient.practitioner && potentialPatient.practitioner["@id"].match(/\/rpps_practitioners\/(\d+)/);

                tryEditPotentialPatient(auth, id, {
                    ...potentialPatient,
                    alternativePhoneNumber: potentialPatient.alternativePhoneNumber || null,
                    answers: potentialPatient.questionnaire.answers?.map((answer) => ({ id: answer["@id"] })),
                    consent: potentialPatient.consent ? potentialPatient.consent["@id"] : null,
                    filledBy: potentialPatient.filledBy["@id"],
                    practitioner: idMatch && idMatch.length === 2 ? { id: potentialPatient.practitioner["@id"] } : null,
                    patientAccountCreatedDate: new Date(),
                }).then(() => navigate("/patients-potentiels"));
            })
            .catch(() => {});

    return (
        <AppLayout navActive={0} user={auth.user} breadcrumbs={breadcrumbs}>
            <div className="grid">
                <main className="col-sm-offset-10 col-sm-80">
                    <h1 className="title-1">Ajouter un nouveau patient</h1>

                    <Form onSubmit={search}>
                        <InputNumber
                            label="Numéro de sécurité sociale du patient"
                            legend="Saisissez le numéro de 13 chiffres"
                            name="searchSsn"
                            length={13}
                            onChange={dispatch.setSearchSsn}
                            requiredNotInLabel
                            required
                        />
                        <div className="credentialsDataUsage">
                            <div className="credentialsDataUsage__content mb-0 mt-2">
                                <p>
                                    Timkl SAS (565 rue Aristide Bergès – 38330 Montbonnot-Saint-Martin) a la qualité de prestataire de service et distributeurs
                                    de matériels au sens de l’article L.5232-3 du Code de la Santé Publique. Il est responsable de traitement au sens du
                                    Règlement Général sur la Protection des Données (RGPD) concernant vos données collectées dans le cadre de la plateforme «
                                    Diabetics Adherence Platform » éditée par le groupe Roche.
                                </p>
                                <p>
                                    L’objet de ces traitements est de permettre une coordination du parcours de soin des patients diabétiques par une infirmière
                                    Timkl conformément aux recommandations de la Haute Autorité de Santé.
                                </p>
                                <p>
                                    Les données contenues dans la plateforme proviennent des professionnels de santé impliqués dans le parcours de soin et de la
                                    part du patient directement. Elles ont vocation à permettre :<br />
                                    1) De vous identifier en tant que patient afin d’éviter tout doublon puis lorsque vous accédez et effectuez des actions sur
                                    la plateforme (conformément à nos obligations légales de sécurité) : vos données d’identification sont conservées pour la
                                    durée d’activité de votre compte augmentée d’un an et les logs sont conservés cinq ans à compter de la collecte.
                                    <br />
                                    2) De générer votre parcours de soin et permettre votre suivi et de communiquer avec vous (y compris par contact
                                    téléphonique ou e-mail) par l’infirmière coordinatrice Timkl (dans la mesure nécessaire à l’administration des soins) : les
                                    données de votre compte sont conservées pour la durée d’activité de ce dernier puis archivées 10 ans sauf indication
                                    contraire de votre part.
                                    <br />
                                    3) De partager ces données avec les professionnels que vous choisissez (avec votre consentement au partage qui vous sera
                                    demandé au cas par cas) : le partage sera actif jusqu’à ce que vous choisissiez de désactiver les accès à l’issue de la
                                    prise en charge.
                                    <br />
                                    4) De générer des statistiques quant aux actions techniques sur la plateforme de façon non individuelle (uniquement dans le
                                    cadre de notre intérêt légitime d’amélioration des services et avec votre consentement qui vous sera demandé en cas d’accès
                                    non nécessaire à votre appareil): ces données seront traitées pour une durée n’excédant pas quelques dizaines de mois.
                                </p>
                                <p>
                                    Pour pouvoir vous fournir nos services, nous collaborons régulièrement avec des tiers internes ou externes au groupe Roche
                                    en Europe, par exemple des affiliées de Roche qui appartiennent au groupe Roche/F. Hoffmann-La Roche Ltd. Les prestations
                                    d’authentification sont réalisées par Gigya, un service fourni par SAP Inc. en Irlande; les données de santé sont hébergées
                                    en Allemagne par AWS (infrastructure) et en Espagne par Accenture (administration) qui sont certifiés à cette fin au regard
                                    des exigences du Code de la Santé Publique. Nous pouvons partager les données traitées sur la Plateforme avec les forces de
                                    l’ordre, organismes publics ou des tiers autorisés au sens du RGPD en réponse à une demande en ce sens ayant fait l’objet
                                    d’une vérification, ainsi qu’avec les autorités de santé françaises autorisées et la caisse d’assurance maladie.
                                </p>
                                <p>
                                    Vous disposez des droits d’accès, de rectification et d’effacement de vos données à caractère personnel, des droits de vous
                                    opposer ou de limiter le traitement de vos données à caractère personnel, du droit à la portabilité des données à caractère
                                    personnel, de retirer votre consentement à tout moment et du droit de définir des directives relatives au sort de vos
                                    données à caractère personnel après votre mort, directement sur la Plateforme ou en vous adressant à dpo.timkl@timkl.fr.
                                    Vous avez le droit d’adresser une réclamation à l’autorité de contrôle compétente : Commission nationale de l'informatique
                                    et des libertés - 3 Place de Fontenoy – 75007 Paris.
                                    <br />
                                </p>
                            </div>
                        </div>
                        <div className="form__group text-center">
                            <InputCheckbox
                                label="En validant la création de dossier, je confirme avoir informé mon patient qui accepte l’accès aux données concernant sa santé (recommandations, nombre de mesures de glycémies, informations de suivi) et le suivi de son parcours de soin (y compris par contact téléphonique ou e-mail) sur la plateforme par l’infirmière coordinatrice Timkl et son équipe."
                                onChange={(val) => dispatch.setDisabled(!val)}
                            />
                            <Link to="/patients-potentiels" className="button button__secondaryButton">
                                Retour
                            </Link>
                            <Button className="button__primaryButton" disabled={disabled} isLoading={auth.searchBySsnLoading}>
                                Ajouter le patient
                            </Button>
                        </div>
                    </Form>

                    {searchFetched && (
                        <div>
                            {!(auth.searchBySsnResult && auth.searchBySsnResult.dap) ? (
                                <Animated animationIn="fadeIn">
                                    {auth.searchBySsnResult && auth.searchBySsnResult.gigya ? (
                                        <div className="bgSuccess radius shadow mt-4 mb-2 pt-2 pl-3 pb-2 pr-3">
                                            Nous vous invitons à compléter le profil du patient
                                        </div>
                                    ) : (
                                        <div className="bgWarning radius shadow mt-4 mb-2 pt-2 pl-3 pb-2 pr-3">
                                            Aucun résultat, nous vous invitons à créer le profil du patient
                                        </div>
                                    )}
                                    <div className="blockItem">
                                        <PatientsAddForm
                                            {...patient}
                                            violations={patients.patientCreateError.violations || {}}
                                            loading={patients.patientCreateLoading}
                                            defaultSsn={parseInt(searchSsn)}
                                            submit={onSubmit}
                                        />
                                    </div>
                                </Animated>
                            ) : (
                                <Animated animationIn="fadeIn">
                                    <div className="bgInfo radius shadow mt-4 mb-2 pt-2 pl-3 pb-2 pr-3">Ce patient est déjà inscrit.</div>
                                </Animated>
                            )}
                        </div>
                    )}
                </main>
            </div>
        </AppLayout>
    );
};

PatientsAddScreen.propTypes = {
    auth: PropTypes.object.isRequired,
    patients: PropTypes.shape({
        patientCreateLoading: PropTypes.bool.isRequired,
        patientCreateError: PropTypes.oneOfType([PropTypes.shape({ violations: PropTypes.object }), PropTypes.bool]).isRequired,
    }),
    potentialPatient: PropTypes.object,
    tryCreatePatient: PropTypes.func.isRequired,
    tryGigyaSearchBySsn: PropTypes.func.isRequired,
    tryFetchPotentialPatient: PropTypes.func.isRequired,
    tryEditPotentialPatient: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    patients: state.patients,
    potentialPatient: state.patients.potentialPatient,
});

const mapDispatchToProps = {
    tryCreatePatient,
    tryGigyaSearchBySsn,
    tryFetchPotentialPatient,
    tryEditPotentialPatient,
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientsAddScreen);
