import React from "react";
import { useNavigate } from "react-router-dom";

const useRequestFromFormAndRedirect = (destination, request, ...params) => {
    const navigate = useNavigate();

    return React.useCallback(
        (form) =>
            request(...params, form)
                .then(() => navigate(destination))
                .catch(() => undefined),
        [request, ...params]
    );
};

export default useRequestFromFormAndRedirect;
