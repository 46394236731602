import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { connect } from "react-redux";
import { trySinglePatient, tryFetchPatientTasks, tryCreatePatientTask, tryEditPatientTask } from "../../actions/patients";
import { tryFetchTaskLabels } from "../../actions/taskLabels";

import { DefaultLoader, Icon, InputCheckbox, Modal, PageTitle, stringIdToId } from "@rdcs/dap-front-library";
import moment from "moment";

import CoordinatorPatientLayout from "../../components/Coordinator/CoordinatorPatientLayout";
import CoordinatorPatientTaskForm from "../../components/Coordinator/CoordinatorPatientTaskForm";
import { getViolations } from "../../utils/utils";

const CoordinatorPatientTask = React.memo(({ task }) => (
    <>
        <span className="tasksListItem__label">{task.label}</span>
        <div className="tasksList__practitioner">
            {task.done ? (
                <div className="tasksListItem__date">Réalisée par {task.doneBy ? task.doneBy.firstName + " " + task.doneBy.lastName : "Praticien anonyme"}</div>
            ) : task.responsible ? (
                <div className="tasksListItem__date">
                    À réaliser par le {task.responsible.firstName} {task.responsible.lastName}
                </div>
            ) : (
                <div className="tasksListItem__date">
                    Créée par {task.createdBy ? task.createdBy.firstName + " " + task.createdBy.lastName : "Praticien anonyme"}
                </div>
            )}
            &nbsp;
            <span className="tasksListItem__date mr-1">
                {task.done
                    ? "le " + moment(task.doneAt).format("DD/MM/YYYY")
                    : task.dueAt
                    ? "avant le " + moment(task.dueAt).format("DD/MM/YYYY")
                    : "le " + moment(task.createdAt).format("DD/MM/YYYY")}
            </span>
            {task.responsible && !task.done && (
                <>
                    <div className="tasksListItem__date">
                        Créée par {task.createdBy.firstName} {task.createdBy.lastName}
                    </div>
                    &nbsp;
                    <span className="tasksListItem__date">le {moment(task.createdAt).format("DD/MM/YYYY")}</span>
                </>
            )}
        </div>
    </>
));

const CoordinatorPatientTasksScreen = ({
    auth,
    patients,
    taskLabels,
    trySinglePatient,
    tryFetchPatientTasks,
    tryCreatePatientTask,
    tryEditPatientTask,
    tryFetchTaskLabels,
}) => {
    const { id } = useParams();
    const patientId = React.useMemo(() => parseInt(id), [id]);
    const createTaskRef = React.useRef(null);

    React.useEffect(() => {
        const promises = [];

        if (patients.patientTasks.length === 0 || stringIdToId(patients.patientTasks[0].patient["@id"]) !== patientId) {
            promises.push(tryFetchPatientTasks(auth, patientId));
        }

        if (taskLabels.list.length === 0) {
            promises.push(tryFetchTaskLabels(auth));
        }

        Promise.all(promises);
    }, []);

    return (
        <PageTitle title="Timkl - Mes Patients">
            <CoordinatorPatientLayout activeNav="actions">
                {patients.patientTasksLoading || patients.singlePatientLoading || patients.taskLabelsLoading ? (
                    <DefaultLoader />
                ) : (
                    <>
                        <div className="dashboardContent__element">
                            <div className="dashboardContent__title">
                                <div className="dashboardContent__titleItem">
                                    <Icon name="actions" size="30px" />
                                    <span className="dashboardContent__titleText">Liste des actions</span>
                                </div>
                            </div>
                            <div className="tasksList">
                                {patients.patientTasks.length === 0 ? (
                                    <div>Aucune action</div>
                                ) : (
                                    patients.patientTasks
                                        .sort((a, b) => {
                                            if (a.done && !b.done) return 1;
                                            if (!a.done && b.done) return -1;
                                            return 0;
                                        })
                                        .map((task) => (
                                            <InputCheckbox
                                                key={task.id}
                                                label={<CoordinatorPatientTask task={task} />}
                                                defaultChecked={task.done}
                                                onChange={(done) =>
                                                    tryEditPatientTask(auth, {
                                                        id: task.id,
                                                        done: done,
                                                    })
                                                }
                                            />
                                        ))
                                )}
                            </div>
                            <Modal
                                buttonContent={
                                    <div className="reportsListItem__add">
                                        <Icon name="add" size="30px" />
                                        <span className="reportsListItem__add-text">Ajouter une action</span>
                                    </div>
                                }
                                ref={createTaskRef}
                                isSmall
                            >
                                <div className="modal__title modal__title--small">Ajouter une action</div>
                                <div className="modal__content">
                                    <CoordinatorPatientTaskForm
                                        patientId={patientId}
                                        patientPractitioners={patients.singlePatient ? patients.singlePatient.accessedBy : []}
                                        taskLabels={taskLabels.list}
                                        isLoading={patients.createTaskLoading}
                                        errors={getViolations(patients.createTaskError)}
                                        onSubmit={(form) => {
                                            tryCreatePatientTask(auth, form).then(() => {
                                                createTaskRef.current?.toggleModal();
                                            });
                                        }}
                                    />
                                </div>
                            </Modal>
                        </div>
                    </>
                )}
            </CoordinatorPatientLayout>
        </PageTitle>
    );
};

CoordinatorPatientTasksScreen.propTypes = {
    auth: PropTypes.object.isRequired,
    patients: PropTypes.object.isRequired,
    taskLabels: PropTypes.object.isRequired,
    trySinglePatient: PropTypes.func.isRequired,
    tryFetchPatientTasks: PropTypes.func.isRequired,
    tryCreatePatientTask: PropTypes.func.isRequired,
    tryEditPatientTask: PropTypes.func.isRequired,
    tryFetchTaskLabels: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, patients, taskLabels }) => ({ auth, patients, taskLabels });

const mapDispatchToProps = {
    trySinglePatient,
    tryFetchPatientTasks,
    tryCreatePatientTask,
    tryEditPatientTask,
    tryFetchTaskLabels,
};

export default connect(mapStateToProps, mapDispatchToProps)(CoordinatorPatientTasksScreen);
