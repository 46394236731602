import React from "react";

import moment from "moment";

const reducer = (prevState, action) => {
    switch (action.type) {
        case "START_REQUEST":
            return {
                ...prevState,
                loading: true,
            };
        case "SET_DATAS":
            return {
                ...prevState,
                loading: false,
                datas: action.datas.map((data) => {
                    let type = "";

                    switch (data.type) {
                        case "entretien":
                            type = "Entretien de suivi";
                            break;
                        case "follow_up":
                            type = "Entretien de suivi";
                            break;
                        case "long_consult":
                            type = "Revue du parcours de soin";
                            break;
                        case "new_break_score":
                            type = "Création d'un questionnaire";
                            break;
                        case "new_patient_by_mg":
                            type = "Ajout patient MG";
                            break;
                        case "note":
                            type = "Rapport partenaire";
                            break;
                        case "patient":
                            type = "Ajout patient";
                            break;
                        case "report":
                            type = "Rapport partenaire";
                            break;
                        default:
                            break;
                    }

                    return {
                        ...data,
                        selected: prevState.datas.find(({ id }) => id === data.id)?.selected || false,
                        type,
                        createdAt: moment(data.createdAt).format("DD/MM/YYYY"),
                        patientBirthday: moment(data.patientBirthday).format("DD/MM/YYYY"),
                    };
                }),
            };
        case "FAILED_LOAD_DATA":
            return {
                ...prevState,
                datas: [],
                loading: false,
            };
        case "SET_MIN_DATE":
            return {
                ...prevState,
                minDate: moment(action.date).toDate(),
            };
        case "SET_MAX_DATE":
            return {
                ...prevState,
                maxDate: moment(action.date).toDate(),
            };
        case "TOGGLE_ALL_DATAS":
            return {
                ...prevState,
                datas: prevState.datas.map((data) => ({ ...data, selected: action.checked })),
            };
        case "TOGGLE_DATA":
            return {
                ...prevState,
                datas: prevState.datas.map((data, i) => ({ ...data, selected: i === action.index ? action.checked : data.selected })),
            };
        default:
            return prevState;
    }
};

const useState = () => {
    const defaultDate = React.useMemo(
        () => ({
            min: moment().startOf("day").subtract(1, "months").toDate(),
            max: moment().startOf("day").toDate(),
        }),
        []
    );

    const defaultState = React.useMemo(
        () => ({
            datas: [],
            loading: true,
            minDate: undefined,
            maxDate: undefined,
        }),
        []
    );

    const [state, dispatch] = React.useReducer(reducer, defaultState);

    const methods = React.useMemo(
        () => ({
            startRequest: () => dispatch({ type: "START_REQUEST" }),

            setDatas: (datas) => dispatch({ type: "SET_DATAS", datas }),

            failedLoadData: () => dispatch({ type: "FAILED_LOAD_DATA" }),

            setMinDate: (date) => dispatch({ type: "SET_MIN_DATE", date }),

            setMaxDate: (date) => dispatch({ type: "SET_MAX_DATE", date }),

            toggleAllDatas: (checked) => dispatch({ type: "TOGGLE_ALL_DATAS", checked }),

            toggleData: (checked, index) => dispatch({ type: "TOGGLE_DATA", checked, index }),
        }),
        []
    );

    return [defaultDate, state, methods];
};

export default useState;
