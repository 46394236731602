import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { InputDateTime, PageTitle, DataTable, ButtonDownloadCSV } from "@rdcs/dap-front-library";

import { tryFetchTrackingBilling } from "../../../actions/statistics";
import AppLayout from "../../../components/Layout/AppLayout";

import Checkbox from "./Checkbox";
import useState from "./useState";

const columns = [
    {
        name: "Date",
        dataIndex: "createdAt",
    },
    {
        name: "N° RPPS du professionel de santé",
        dataIndex: "practitionerRpps",
    },
    {
        name: "Type d'action",
        dataIndex: "type",
    },
    {
        name: "NIR du patient",
        dataIndex: "patientSsn",
    },
    {
        name: "Date de naissance du patient",
        dataIndex: "patientBirthday",
    },
    {
        name: "Nom du patient",
        dataIndex: "patientLastname",
    },
    {
        name: "Prénom du patient",
        dataIndex: "patientFirstname",
    },
];

const breadcrumbs = [{ label: "Tableau de bord", link: "/dashboard" }, { label: "Actions à facturer" }];

const headersCsv = columns.map((column) => ({ label: column.name, key: column.dataIndex }));

const TrackingBillingScreen = ({ tryFetchTrackingBilling }) => {
    const [defaultDate, { datas, loading, minDate, maxDate }, { startRequest, setDatas, failedLoadData, setMinDate, setMaxDate, toggleAllDatas, toggleData }] =
        useState();

    const datasSelected = React.useMemo(() => datas.filter(({ selected }) => selected), [datas]);
    const finalColumns = React.useMemo(
        () => [
            {
                dataIndex: "selected",
                render: () => <Checkbox checked={datasSelected.length === datas.length} onChange={toggleAllDatas} />,

                renderData: (data, index) => <Checkbox checked={data} onChange={(checked) => toggleData(checked, index)} />,
            },
            ...columns,
        ],
        [datasSelected, toggleAllDatas, toggleData]
    );

    React.useEffect(() => {
        if (minDate !== undefined && maxDate !== undefined) {
            startRequest();
            tryFetchTrackingBilling(minDate, maxDate).then(setDatas).catch(failedLoadData);
        }
    }, [minDate, maxDate]);

    return (
        <PageTitle title="Timkl - Suivi facturation">
            <AppLayout navActive={0} breadcrumbs={breadcrumbs}>
                {
                    <>
                        <h1 className="title-1">Liste des actions à facturer</h1>
                        <section className="trackingBilling__header mb-1">
                            <div className="container__dateAlign align-items-center">
                                <div className="dataPatientItem__inputGroup">
                                    <InputDateTime label="Du: " onChange={setMinDate} defaultValue={defaultDate.min} maxDate={maxDate} className="mr-2" />
                                </div>
                                <div className="dataPatientItem__inputGroup">
                                    <InputDateTime
                                        label="Au: "
                                        onChange={setMaxDate}
                                        defaultValue={defaultDate.max}
                                        minDate={minDate}
                                        maxDate={defaultDate.max}
                                    />
                                </div>
                            </div>
                            <p>
                                <ButtonDownloadCSV
                                    headers={headersCsv}
                                    datas={datasSelected}
                                    filename="actions à facturer"
                                    className="button__primaryButton"
                                    disabled={datasSelected.length === 0}
                                >
                                    Exporter en CSV
                                </ButtonDownloadCSV>
                            </p>
                        </section>
                        <DataTable columns={finalColumns} datas={datas} dataIsLoading={loading} />
                    </>
                }
            </AppLayout>
        </PageTitle>
    );
};

TrackingBillingScreen.propTypes = {
    tryFetchTrackingBilling: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    tryFetchTrackingBilling,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackingBillingScreen);
