import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { SingleQuestionnaireHeader } from "@rdcs/dap-front-library";
import QuestionnaireAnswerDisplay from "./QuestionnaireAnswerDisplay";
import { formatDate } from "../../utils/utils";

const SinglePastQuestionnaire = ({ questionnaire, isInsulinoTreated, breakScoreQuestions, breakScoreNoInsulinQuestions, setFillNewQuestionnaire }) => {
    const date = formatDate(questionnaire.createdAt);
    return (
        <div>
            <SingleQuestionnaireHeader
                date={date}
                setFillNewQuestionnaire={setFillNewQuestionnaire}
                buttonClassName="timkl__backgroundColor--orange-dark-important"
            />
            {questionnaire && (
                <QuestionnaireAnswerDisplay
                    questions={isInsulinoTreated ? breakScoreQuestions : breakScoreNoInsulinQuestions}
                    answers={questionnaire.answers}
                    score={questionnaire.score}
                    singleQuestionnaire={true}
                />
            )}
        </div>
    );
};

SinglePastQuestionnaire.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    questionnaire: PropTypes.object,
    isInsulinoTreated: PropTypes.bool.isRequired,
    breakScoreQuestions: PropTypes.arrayOf(PropTypes.object),
    breakScoreNoInsulinQuestions: PropTypes.arrayOf(PropTypes.object),
    breakScoreAnswers: PropTypes.arrayOf(PropTypes.object),
    setFillNewQuestionnaire: PropTypes.func.isRequired,
};

const mapStateToProps = ({ questions: { breakScoreQuestions, breakScoreAnswers, breakScoreNoInsulinQuestions } }) => ({
    breakScoreQuestions,
    breakScoreNoInsulinQuestions,
    breakScoreAnswers,
});

export default connect(mapStateToProps)(SinglePastQuestionnaire);
