import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { connect } from "react-redux";
import { tryFetchDiagnosticRecommendations } from "../../actions/patients";
import { tryCreatePatientAppointment, tryEditPatientAppointment, tryFetchPatientAppointments } from "../../actions/patients";

import { DefaultLoader, Icon, AppointmentRealised, AppointmentRecommended, AppointmentScheduled, PageTitle } from "@rdcs/dap-front-library";
import moment from "moment";

import CoordinatorPatientLayout from "../../components/Coordinator/CoordinatorPatientLayout";

const CoordinatorPatientExamsScreen = ({
    auth,
    patients,
    tryFetchPatientAppointments,
    tryCreatePatientAppointment,
    tryEditPatientAppointment,
    tryFetchDiagnosticRecommendations,
}) => {
    const { id } = useParams();
    const patientId = React.useMemo(() => parseInt(id), [id]);

    // Retrive all consultation's appointments.
    const exams = React.useMemo(
        () => patients.patientAppointments.filter((item) => item.recommendations.find((r) => r.type === "examination")),
        [patients.patientAppointments]
    );

    // Retrive all consultation's appointments planned.
    const appointmentsPlanned = React.useMemo(
        () => exams.filter((item) => item.doneAt === null).sort((a, b) => new Date(a.appointment?.date || a.dueAt) - new Date(b.appointment?.date || b.dueAt)),
        [exams]
    );

    // Retrive all consultation's appointments done.
    const appointmentsDone = React.useMemo(
        () => exams.filter(({ doneAt }) => doneAt !== null).sort((a, b) => new Date(b.doneAt) - new Date(a.doneAt)),
        [exams]
    );

    // Retrive all consultation's recommendations not planned.
    const recosExam = React.useMemo(() => {
        const now = moment().unix();

        return (
            patients.diagnosticRecommendations
                // Keep consulations only
                .filter(({ recommendation: { type, id: _id } }) => {
                    if (type === "examination") {
                        for (const exam of exams) {
                            if (exam.recommendations.find((recommendation) => recommendation.id === _id)) {
                                return false;
                            }
                        }
                        return true;
                    }
                    return false;
                })
                // Bind Diagnostic recos with appointments
                .map((reco) => ({ ...reco, statusColor: now < moment(reco.dueAt).unix() ? "orange" : "red" }))
                // Remove all consultation done.
                .filter(({ appointment = null }) => !appointment?.doneAt)
                // Sort by color and after, sort by dueAt
                .sort((a, b) => {
                    const codeA = a.statusColor.charCodeAt(0);
                    const codeB = b.statusColor.charCodeAt(0);

                    if (codeA !== codeB) {
                        return codeB - codeA;
                    }
                    return new Date(a.appointment?.date || a.dueAt) - new Date(b.appointment?.date || b.dueAt);
                })
        );
    }, [patients.diagnosticRecommendations, exams]);

    const onPatientLoaded = React.useCallback((singlePatient) => {
        tryFetchDiagnosticRecommendations(auth, singlePatient);
    }, []);

    return (
        <PageTitle title="Timkl - Mes Patients">
            <CoordinatorPatientLayout activeNav="examens" onPatientLoaded={onPatientLoaded}>
                {patients.diagnosticRecommendationsLoading || patients.patientAppointmentsLoading ? (
                    <DefaultLoader />
                ) : (
                    <>
                        {/* Examens */}
                        <div className="dashboardContent__element">
                            <div className="dashboardContent__title">
                                <div className="dashboardContent__titleItem">
                                    <div className=" form__group d-flex align-items-center">
                                        <Icon name="stats" size="30px" className="mr-1" />
                                        <h1 className="title-1">Examens recommandés</h1>
                                    </div>
                                    <p className="txtSizeSmall text-gray mt-1 mb-2">Liste de tous les examens recommandés pour le patient.</p>
                                </div>
                            </div>
                            <div className="consultsList">
                                {patients.singlePatientLoading || patients.diagnosticRecommendationsLoading ? (
                                    <DefaultLoader />
                                ) : recosExam && recosExam.length > 0 ? (
                                    recosExam.map((recoInstance) => (
                                        <AppointmentRecommended
                                            key={recoInstance.id}
                                            dueAt={recoInstance.dueAt}
                                            statusColor={recoInstance.statusColor}
                                            recommendation={recoInstance.recommendation}
                                            showDescription={false}
                                            exams={patients.diagnosticRecommendations.filter(
                                                (r) => r.recommendation.consultationName === recoInstance.recommendation.title
                                            )}
                                            patientIri={"/patients/" + patientId}
                                            searchPractitionerAction={(form) => (auth, form)}
                                            createAppointmentLoading={patients.createAppointmentLoading}
                                            createAppointmentAction={(appointment) =>
                                                tryCreatePatientAppointment(appointment).then(() => tryFetchPatientAppointments(patientId))
                                            }
                                        />
                                    ))
                                ) : (
                                    <p>Aucun examen recommandé</p>
                                )}
                            </div>
                        </div>
                        {/* Examens planifiés */}
                        <div className="dashboardContent__element mt-4">
                            <div className="dashboardContent__title">
                                <div className="dashboardContent__titleItem">
                                    <div className=" form__group d-flex align-items-center">
                                        <Icon name="stats" size="30px" className="mr-1" />
                                        <h1 className="title-1">Examens planifiés</h1>
                                    </div>
                                    <p className="txtSizeSmall text-gray mt-1 mb-2">Liste de tous les examens planifiés.</p>
                                </div>
                            </div>
                            <div className="consultsList">
                                {patients.singlePatientLoading ? (
                                    <DefaultLoader />
                                ) : appointmentsPlanned && appointmentsPlanned.length > 0 ? (
                                    appointmentsPlanned.map((appointment) => (
                                        <AppointmentScheduled
                                            key={appointment.id}
                                            appointment={appointment}
                                            confirmAppointmentLoading={patients.editAppointmentLoading}
                                            confirmAppointmentAction={(form) =>
                                                tryEditPatientAppointment(auth, form).then(() => tryFetchPatientAppointments(patientId))
                                            }
                                        />
                                    ))
                                ) : (
                                    <p>Aucun examen planifié</p>
                                )}
                            </div>
                        </div>
                        {/* Examens réalisés */}
                        <div className="dashboardContent__element mt-4">
                            <div className="dashboardContent__title">
                                <div className="dashboardContent__titleItem">
                                    <div className=" form__group d-flex align-items-center">
                                        <Icon name="stats" size="30px" className="mr-1" />
                                        <h1 className="title-1">Examens réalisés</h1>
                                    </div>
                                    <p className="txtSizeSmall text-gray mt-1 mb-2">Tous les examens réalisés par le patient</p>
                                </div>
                            </div>
                            <div className="consultsList">
                                {patients.diagnosticRecommendationsLoading ? (
                                    <DefaultLoader />
                                ) : appointmentsDone && appointmentsDone.length > 0 ? (
                                    appointmentsDone.map((appointment) => <AppointmentRealised key={appointment.id} appointment={appointment} />)
                                ) : (
                                    <p>Aucun examen réalisé</p>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </CoordinatorPatientLayout>
        </PageTitle>
    );
};

CoordinatorPatientExamsScreen.propTypes = {
    auth: PropTypes.object.isRequired,
    patients: PropTypes.object.isRequired,
    tryFetchPatientAppointments: PropTypes.func.isRequired,
    tryCreatePatientAppointment: PropTypes.func.isRequired,
    tryEditPatientAppointment: PropTypes.func.isRequired,
    tryFetchDiagnosticRecommendations: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, patients }) => ({ auth, patients });

const mapDispatchToProps = {
    tryFetchPatientAppointments,
    tryCreatePatientAppointment,
    tryEditPatientAppointment,
    tryFetchDiagnosticRecommendations,
};

export default connect(mapStateToProps, mapDispatchToProps)(CoordinatorPatientExamsScreen);
