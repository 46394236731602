import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Gauge, Button, Icon } from "@rdcs/dap-front-library";
import { Animated } from "react-animated-css";
import LeftImage from "@rdcs/dap-front-library/static/images/illu_pharma.svg";
import { tryDeleteQuestionnairePatient } from "../../actions/questionnaire";

const BeckScore = ({ score, questionnaireId, setIsScoreScreen, tryDeleteQuestionnairePatient }) => {
    const navigate = useNavigate();

    const [depressionLevel, setDepressionLevel] = React.useState("");
    const [depressionColor, setDepressionColor] = React.useState("");

    const { id } = useParams();
    const patientId = React.useMemo(() => parseInt(id), [id]);

    React.useEffect(() => {
        let level = "";
        let color = "";

        if (score >= 0 && score <= 3) {
            level = "pas de dépression";
            color = "green";
        } else if (score >= 4 && score <= 15) {
            level = "dépression légère à modérée";
            color = "orange";
        } else {
            level = "dépression sévère";
            color = "red";
        }

        setDepressionLevel(level);
        setDepressionColor(color);
    }, [score]);

    function handleClickDelete() {
        tryDeleteQuestionnairePatient(questionnaireId).then(() => {
            setIsScoreScreen(false);
        });
    }

    function handleClickGoNext() {
        navigate(`/facilitateurs/patient/${patientId}/dashboard`);
    }
    return (
        <Animated animationIn="fadeIn">
            <div className="breakScoreQuestionnaire__container__step3">
                <div className="breakScoreQuestionnaire__container__step3--leftImage">
                    <img src={LeftImage} />
                </div>
                <div>
                    <div className="breakScoreQuestionnaire__container__step3--rightForm">
                        <div>
                            <div className="form__group">
                                <p className="title-4 mb-4 d-flex align-items-center">
                                    {depressionLevel === "pas de dépression"
                                        ? "Au vu des réponses, le patient ne présente"
                                        : "Au vu des réponses, le patient présente une"}
                                    <span className={`beckLevelColor_${depressionColor} beckLevelColor ml-1`}>
                                        <div className={`statusDot statusDot--beck statusDot--${depressionColor} mr-1`}></div> {depressionLevel}
                                    </span>
                                </p>
                            </div>
                            <div className="beckIntroGauge mb-2">
                                <div className="d-flex align-items-center">
                                    <div className="statusDot statusDot--beck mr-1 ml-1 statusDot--green"></div>0 à 3 : pas de dépression
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="statusDot statusDot--beck mr-1 ml-1 statusDot--orange"></div>4 à 7 : dépression légère
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="statusDot statusDot--beck mr-1 ml-1 statusDot--orange"></div>8 à 15 : dépression modérée
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="statusDot statusDot--beck mr-1 ml-1 statusDot--red"></div>16 et + : dépression sévère
                                </div>
                            </div>
                            <Gauge beck value={score} info="Score du patient" min={0} max={24} />
                        </div>

                        <section className="mt-4 right d-flex">
                            <Button onClick={handleClickDelete} className=" button__secondaryButton d-flex align-items-center">
                                Ne pas enregistrer ce questionnaire
                                <Icon name="arrow-right" size={15} />
                            </Button>
                            <Button onClick={handleClickGoNext} className=" button__primaryButton d-flex align-items-center">
                                Revenir au profil patient
                                <Icon name="arrow-right" size={15} />
                            </Button>
                        </section>
                    </div>
                </div>
            </div>
        </Animated>
    );
};

BeckScore.propTypes = {
    score: PropTypes.number.isRequired,
    questionnaireId: PropTypes.number.isRequired,
    setIsScoreScreen: PropTypes.func.isRequired,
    tryDeleteQuestionnairePatient: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    tryDeleteQuestionnairePatient,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BeckScore);
