import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import { Icon } from "@rdcs/dap-front-library";

import AppLayout from "../../components/Layout/AppLayout";

import TreatmentTab from "./Tabs/TreatmentTab";
import ComplicationTab from "./Tabs/ComplicationTab";
import StudyQuestionsTab from "./Tabs/StudyQuestionsTab";
import BreakScoreQuestionsTab from "./Tabs/BreakScoreQuestionsTab";
import BreakScoreNoInsulinQuestionsTab from "./Tabs/BreakScoreNoInsulinQuestionsTab";
import OptionalRecommendationsTab from "./Tabs/OptionalRecommendationsTab";
import AdminTab from "./Tabs/AdminTab";
import ModeratorTab from "./Tabs/ModeratorTab";
import CoordinatorTab from "./Tabs/CoordinatorTab";
import InformationsTab from "./Tabs/InformationsTab";
import PartnersListTab from "./Tabs/PartnersListTab";
import TaskLabelTab from "./Tabs/TaskLabelTab";

import { tryFetchTreatmentGroups, tryActivateTreatment, trySwapTreatmentOrder } from "../../actions/treatments";
import Questions from "../../actions/questions";
import { tryFetchComplications, tryActivateComplication, trySwapComplicationOrder } from "../../actions/complications";
import { tryFetchAdmins, tryRemoveAdmin } from "../../actions/admins";
import { tryFetchModerators, tryRemoveModerator } from "../../actions/moderators";
import { tryFetchPartners, tryRemovePartner } from "../../actions/partners";
import { tryFetchCoordinators, tryRemoveCoordinator } from "../../actions/coordinators";
import { tryDeleteTaskLabel, tryEditTaskLabel, tryFetchTaskLabels, trySwapTaskLabelOrder } from "../../actions/taskLabels";
import { tryFetchAllDefaultRecommendation, tryDisableAllDefaultRecommendation, tryEnableAllDefaultRecommendation } from "../../actions/recommendations";

import { tryFetchPointsOfAttention, tryDeletePointsOfAttention } from "../../actions/pointsOfAttention";
import { tryFetchThematic, tryDeleteThematic } from "../../actions/thematics";

import ThematicTabs from "./Tabs/ThematicTabs";
import PointsOfAttentionTab from "./Tabs/PointsOfAttentionTab";

const {
    tryFetchStudyQuestions,
    tryActivateStudyQuestion,
    trySwapStudyQuestionOrder,

    tryFetchBreakScoreQuestions,
    tryActivateBreakScoreQuestion,
    trySwapBreakScoreQuestionOrder,

    tryFetchBreakScoreNoInsulinQuestions,
    tryActivateBreakScoreNoInsulinQuestion,
    trySwapBreakScoreNoInsulinQuestionOrder,

    tryFetchDiagnosticQuestions,
    tryActivateDiagnosticQuestion,
    trySwapDiagnosticQuestionOrder,
} = Questions;

// Left navigation list
const navList = [
    {
        groupTitle: "Configuration",
        listLink: [
            { id: "treatment-groups", title: "Groupes de traitement", icon: "datas" },
            { id: "complications", title: "Complications", icon: "complications-insuffisance-renale" },
            { id: "actions", title: "Actions", icon: "datas" },
            { id: "study-questions", title: "Questions d'étude", icon: "informations" },
            { id: "break-score-questions", title: "Questions de rupture pour patient insulinotraité", icon: "informations" },
            { id: "break-score-no-insulin-questions", title: "Questions de rupture pour patient non-insulinotraité", icon: "informations" },
            { id: "optional-recommendations", title: "Recommandations optionnelles", icon: "pro" },
            { id: "informations-questions", title: "Informations", icon: "informations" },
            { id: "partners-list", title: "Liste des partenaires", icon: "pro" },
            { id: "points-of-attention", title: "Points de vigilance", icon: "ic-health" },
            { id: "thematic", title: "Thématiques des entretiens de suivi", icon: "ic-health" },
        ],
    },
    {
        groupTitle: "Droits d'accès",
        listLink: [
            { id: "admin", title: "Administrateurs", icon: "informations" },
            { id: "moderator", title: "Modérateurs", icon: "stats" },
            { id: "coordinator", title: "Facilitateurs", icon: "pro" },
        ],
    },
];

const breadcrumbs = [{ label: "configuration" }];

// Render componant depending the selected link on navLink
const renderTab = (index, props) => {
    const {
        // Attributs
        treatments,
        questions,
        admins,
        moderators,
        complications,
        coordinators,
        taskLabels,
        recommendations,
        partners,
        pointsOfAttention,
        thematic,

        // Methods
        tryFetchAdmins,
        tryRemoveAdmin,
        tryFetchModerators,
        tryRemoveModerator,
        tryFetchPartners,
        tryRemovePartner,
        tryFetchCoordinators,
        tryRemoveCoordinator,
        tryActivateComplication,
        tryActivateTreatment,
        tryActivateStudyQuestion,
        tryActivateBreakScoreQuestion,
        tryActivateBreakScoreNoInsulinQuestion,
        tryActivateDiagnosticQuestion,
        trySwapTreatmentOrder,
        trySwapStudyQuestionOrder,
        trySwapBreakScoreQuestionOrder,
        trySwapBreakScoreNoInsulinQuestionOrder,
        trySwapComplicationOrder,
        trySwapTaskLabelOrder,
        trySwapDiagnosticQuestionOrder,
        tryDeleteTaskLabel,
        tryDisableAllDefaultRecommendation,
        tryEnableAllDefaultRecommendation,
        tryDeletePointsOfAttention,
        tryDeleteThematic,
    } = props;

    switch (index) {
        case "treatment-groups":
            return (
                <TreatmentTab
                    treatments={treatments.treatmentGroups}
                    loading={treatments.treatmentGroupsLoading}
                    swapOrder={trySwapTreatmentOrder}
                    isSwapingOrder={treatments.swapOrderLoading}
                    activate={tryActivateTreatment}
                />
            );
        case "actions":
            return (
                <TaskLabelTab
                    taskLabels={taskLabels.list}
                    loading={taskLabels.listLoading}
                    swapOrder={trySwapTaskLabelOrder}
                    isSwapingOrder={taskLabels.swapOrderLoading}
                    delete={tryDeleteTaskLabel}
                />
            );
        case "complications":
            return (
                <ComplicationTab
                    complications={complications.list}
                    loading={complications.listLoading}
                    swapOrder={trySwapComplicationOrder}
                    isSwapingOrder={complications.swapOrderLoading}
                    activate={tryActivateComplication}
                />
            );
        case "study-questions":
            return (
                <StudyQuestionsTab
                    studyQuestions={questions.studyQuestions}
                    loading={questions.studyQuestionsLoading}
                    swapOrder={trySwapStudyQuestionOrder}
                    isSwapingOrder={questions.swapOrderLoading}
                    activate={tryActivateStudyQuestion}
                />
            );
        case "break-score-questions":
            return (
                <BreakScoreQuestionsTab
                    breakScoreQuestions={questions.breakScoreQuestions}
                    loading={questions.breakScoreQuestionsLoading}
                    swapOrder={trySwapBreakScoreQuestionOrder}
                    isSwapingOrder={questions.swapOrderLoading}
                    activate={tryActivateBreakScoreQuestion}
                />
            );
        case "break-score-no-insulin-questions":
            return (
                <BreakScoreNoInsulinQuestionsTab
                    breakScoreNoInsulinQuestions={questions.breakScoreNoInsulinQuestions}
                    loading={questions.breakScoreNoInsulinQuestionsLoading}
                    swapOrder={trySwapBreakScoreNoInsulinQuestionOrder}
                    isSwapingOrder={questions.swapOrderLoading}
                    activate={tryActivateBreakScoreNoInsulinQuestion}
                />
            );
        case "optional-recommendations":
            return (
                <OptionalRecommendationsTab
                    optionnalRecos={recommendations.defaultValue}
                    loading={false}
                    enable={tryEnableAllDefaultRecommendation}
                    disable={tryDisableAllDefaultRecommendation}
                />
            );
        case "informations-questions":
            return (
                <InformationsTab
                    diagnosticQuestions={questions.diagnosticQuestions}
                    loading={questions.diagnosticQuestionsLoading}
                    activate={tryActivateDiagnosticQuestion}
                    swapOrder={trySwapDiagnosticQuestionOrder}
                    isSwapingOrder={questions.swapOrderLoading}
                />
            );
        case "partners-list":
            return <PartnersListTab partners={partners.list} loading={partners.listLoading} onFetch={tryFetchPartners} delete={tryRemovePartner} />;
        case "points-of-attention":
            return (
                <PointsOfAttentionTab
                    pointsOfAttention={pointsOfAttention.datas}
                    loading={pointsOfAttention.fetchLoading}
                    delete={tryDeletePointsOfAttention}
                />
            );
        case "thematic":
            return <ThematicTabs thematic={thematic.datas} loading={thematic.fetchLoading} delete={tryDeleteThematic} />;
        case "admin":
            return <AdminTab admins={admins.list} loading={admins.listLoading} onFetch={tryFetchAdmins} delete={tryRemoveAdmin} />;
        case "moderator":
            return <ModeratorTab moderators={moderators.list} loading={moderators.listLoading} onFetch={tryFetchModerators} delete={tryRemoveModerator} />;
        case "coordinator":
            return (
                <CoordinatorTab
                    coordinators={coordinators.list}
                    loading={coordinators.listLoading}
                    onFetch={tryFetchCoordinators}
                    delete={tryRemoveCoordinator}
                />
            );
        default:
            break;
    }
    return <></>;
};

const fetchData = ({
    auth,
    tryFetchTreatmentGroups,
    tryFetchStudyQuestions,
    tryFetchBreakScoreQuestions,
    tryFetchBreakScoreNoInsulinQuestions,
    tryFetchComplications,
    tryFetchAdmins,
    tryFetchModerators,
    tryFetchPartners,
    tryFetchCoordinators,
    tryFetchTaskLabels,
    tryFetchAllDefaultRecommendation,
    tryFetchDiagnosticQuestions,
    tryFetchPointsOfAttention,
    tryFetchThematic,
}) => {
    tryFetchTreatmentGroups(auth);
    tryFetchStudyQuestions();
    tryFetchBreakScoreQuestions();
    tryFetchBreakScoreNoInsulinQuestions();
    tryFetchComplications(auth);
    tryFetchAdmins(auth);
    tryFetchModerators(auth);
    tryFetchPartners(auth);
    tryFetchCoordinators(auth);
    tryFetchTaskLabels(auth);
    tryFetchAllDefaultRecommendation(auth);
    tryFetchDiagnosticQuestions();
    tryFetchPointsOfAttention();
    tryFetchThematic();
};

const SettingsScreen = ({ ...props }) => {
    const location = useLocation();
    // State
    const [tab, setTab] = React.useState(location.tab || "treatment-groups");
    const [sidebarOpen, setSidebarOpen] = React.useState(false);
    const toggle = () => setSidebarOpen(!sidebarOpen);

    React.useEffect(() => {
        document.title = "Timkl - Configuration";
        fetchData(props);
    }, []);

    return (
        <AppLayout navActive={2} breadcrumbs={breadcrumbs}>
            <div className="contentHolder">
                <button className={`sidebarOpenBtn ${sidebarOpen ? " is-open" : ""}`} onClick={toggle}>
                    <div className="sidebarOpenBtn__burgerSlash" />
                    <div className="sidebarOpenBtn__burgerSlash" />
                    <div className="sidebarOpenBtn__burgerSlash" />
                </button>
                <button className={`sidebarCloseBg ${sidebarOpen ? " is-open" : ""}`} onClick={() => setSidebarOpen(false)} />
                <aside className={`contentHolder__sidebar ${sidebarOpen ? " is-open" : ""}`}>
                    <nav className="contentHolder__nav">
                        {navList.map((navGroup, index) => (
                            <div className="contentHolder__navGroup" key={index}>
                                <div className="contentHolder__navGroup-title">{navGroup.groupTitle}</div>
                                {navGroup.listLink.map(({ id, icon, title }) => (
                                    <button key={id} onClick={() => setTab(id)} className={`contentHolder__navItem ${id === tab ? "active" : ""}`}>
                                        <span className="contentHolder__navItem-icon">
                                            <Icon name={icon} />
                                        </span>
                                        {title}
                                    </button>
                                ))}
                            </div>
                        ))}
                    </nav>
                </aside>
                <div className="contentHolder__article">{renderTab(tab, props)}</div>
            </div>
        </AppLayout>
    );
};

SettingsScreen.propTypes = {
    /* Auth */
    auth: PropTypes.object.isRequired,

    /* Treatments */
    treatments: PropTypes.object.isRequired,
    tryFetchTreatmentGroups: PropTypes.func.isRequired,
    tryActivateTreatment: PropTypes.func.isRequired,
    trySwapTreatmentOrder: PropTypes.func.isRequired,

    /* Complications */
    complications: PropTypes.object.isRequired,
    tryFetchComplications: PropTypes.func.isRequired,
    tryActivateComplication: PropTypes.func.isRequired,
    trySwapComplicationOrder: PropTypes.func.isRequired,

    /* TaskLabels */
    taskLabels: PropTypes.object.isRequired,
    tryFetchTaskLabels: PropTypes.func.isRequired,
    trySwapTaskLabelOrder: PropTypes.func.isRequired,
    tryEditTaskLabel: PropTypes.func.isRequired,
    tryDeleteTaskLabel: PropTypes.func.isRequired,

    /* Questions */
    /* Study Questions */
    questions: PropTypes.object.isRequired,
    tryFetchStudyQuestions: PropTypes.func.isRequired,
    tryActivateStudyQuestion: PropTypes.func.isRequired,
    trySwapStudyQuestionOrder: PropTypes.func.isRequired,

    /* Break Score Questions */
    tryFetchBreakScoreQuestions: PropTypes.func.isRequired,
    tryActivateBreakScoreQuestion: PropTypes.func.isRequired,
    trySwapBreakScoreQuestionOrder: PropTypes.func.isRequired,

    /* Break Score No Insulin Questions */
    tryFetchBreakScoreNoInsulinQuestions: PropTypes.func.isRequired,
    tryActivateBreakScoreNoInsulinQuestion: PropTypes.func.isRequired,
    trySwapBreakScoreNoInsulinQuestionOrder: PropTypes.func.isRequired,

    /* Optional recommendations */
    recommendations: PropTypes.object.isRequired,
    tryFetchAllDefaultRecommendation: PropTypes.func.isRequired,
    tryDisableAllDefaultRecommendation: PropTypes.func.isRequired,
    tryEnableAllDefaultRecommendation: PropTypes.func.isRequired,

    /* Admins */
    admins: PropTypes.object.isRequired,
    tryFetchAdmins: PropTypes.func.isRequired,
    tryRemoveAdmin: PropTypes.func.isRequired,

    /* Moderators */
    moderators: PropTypes.object.isRequired,
    tryFetchModerators: PropTypes.func.isRequired,
    tryRemoveModerator: PropTypes.func.isRequired,

    /* Moderators */
    partners: PropTypes.object.isRequired,
    tryFetchPartners: PropTypes.func.isRequired,
    tryRemovePartner: PropTypes.func.isRequired,

    /* Coordinators */
    coordinators: PropTypes.object.isRequired,
    tryFetchCoordinators: PropTypes.func.isRequired,
    tryRemoveCoordinator: PropTypes.func.isRequired,

    /* Informations Questions */
    tryFetchDiagnosticQuestions: PropTypes.func.isRequired,
    tryActivateDiagnosticQuestion: PropTypes.func.isRequired,
    trySwapDiagnosticQuestionOrder: PropTypes.func.isRequired,

    /* Points of attention */
    tryFetchPointsOfAttention: PropTypes.func.isRequired,
    tryDeletePointsOfAttention: PropTypes.func.isRequired,
};

const mapStateToProps = ({
    auth,
    treatments,
    questions,
    admins,
    moderators,
    complications,
    coordinators,
    taskLabels,
    recommendations,
    partners,
    pointsOfAttention,
    thematic,
}) => ({
    auth,
    treatments,
    questions,
    admins,
    moderators,
    complications,
    coordinators,
    taskLabels,
    recommendations,
    partners,
    pointsOfAttention,
    thematic,
});

const mapDispatchToProps = {
    tryFetchTreatmentGroups,
    tryFetchAdmins,
    tryFetchModerators,
    tryFetchPartners,
    tryFetchStudyQuestions,
    tryFetchBreakScoreQuestions,
    tryFetchDiagnosticQuestions,
    tryFetchBreakScoreNoInsulinQuestions,
    tryRemoveAdmin,
    tryRemoveModerator,
    tryRemovePartner,
    tryFetchComplications,
    tryActivateComplication,
    tryActivateTreatment,
    tryActivateStudyQuestion,
    tryActivateBreakScoreQuestion,
    tryActivateBreakScoreNoInsulinQuestion,
    tryActivateDiagnosticQuestion,
    trySwapTreatmentOrder,
    trySwapStudyQuestionOrder,
    trySwapBreakScoreQuestionOrder,
    trySwapBreakScoreNoInsulinQuestionOrder,
    trySwapComplicationOrder,
    trySwapDiagnosticQuestionOrder,
    tryFetchCoordinators,
    tryRemoveCoordinator,
    tryFetchTaskLabels,
    trySwapTaskLabelOrder,
    tryEditTaskLabel,
    tryDeleteTaskLabel,
    tryFetchAllDefaultRecommendation,
    tryDisableAllDefaultRecommendation,
    tryEnableAllDefaultRecommendation,
    tryFetchPointsOfAttention,
    tryDeletePointsOfAttention,
    tryFetchThematic,
    tryDeleteThematic,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsScreen);
