import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { tryFetchProfile, tryEditProfile, tryUpdateUserInfos } from "../../actions/profile";

import { Animated } from "react-animated-css";

import { DefaultLoader } from "@rdcs/dap-front-library";

import ProfileForm from "../../components/Profile/ProfileForm";
import AppLayout from "../../components/Layout/AppLayout";
import { getViolations } from "../../utils/utils";

const ProfileScreen = ({ auth, profile: { profile, profileEditLoading, profileEditError }, tryFetchProfile, tryEditProfile, tryUpdateUserInfos }) => {
    React.useEffect(() => {
        document.title = "Timkl - Profil";

        if (!profile) tryFetchProfile(auth);
    }, []);

    const profileEditErrorViolations = React.useMemo(() => getViolations(profileEditError), [profileEditError]);

    return (
        <AppLayout navActive={null}>
            <main className="col-sm-offset-10 col-sm-80">
                <h1 className="title-1">Mon profil</h1>
                <div>
                    {profile ? (
                        <Animated animationIn="fadeIn">
                            <ProfileForm
                                submit={(prof) =>
                                    tryEditProfile(auth, prof)
                                        .then((updatedProfile) => {
                                            return tryUpdateUserInfos(updatedProfile.avatar, updatedProfile.firstName, updatedProfile.lastName);
                                        })
                                        .catch(console.error)
                                }
                                profile={profile}
                                profileEditLoading={profileEditLoading}
                                profileEditError={profileEditError}
                                violations={profileEditErrorViolations}
                            />
                        </Animated>
                    ) : (
                        <DefaultLoader />
                    )}
                </div>
            </main>
        </AppLayout>
    );
};

ProfileScreen.propTypes = {
    /* Auth */
    auth: PropTypes.object.isRequired,

    /* profile */
    profile: PropTypes.object.isRequired,
    tryFetchProfile: PropTypes.func.isRequired,
    tryEditProfile: PropTypes.func.isRequired,
    tryUpdateUserInfos: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.profile,
});

const mapDispatchToProps = {
    tryFetchProfile,
    tryEditProfile,
    tryUpdateUserInfos,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileScreen);
