import React from "react";

import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";

import { Button, InputText, InputEmail, Icon, SelectMultiple } from "@rdcs/dap-front-library";

const CoordinatorForm = ({
    editedCoordinator: {
        firstName: editedCoordinatorFirstName = "",
        lastName: editedCoordinatorLastName = "",
        email: editedCoordinatorEmail = "",
        departments: editedCoordinatorDepartments = [],
    } = {},
    departments,
    errors,
    onSubmit,
    isLoading,
}) => {
    const location = useLocation();

    const departmentsInputDefault = React.useMemo(() => editedCoordinatorDepartments.map((d) => d["@id"]), []);
    const departmentsOptions = React.useMemo(
        () =>
            departments
                .sort((a, b) => (a.code < b.code ? -1 : 1))
                .map((d) => ({
                    id: d.id,
                    title: d.code + " - " + d.name,
                    value: d["@id"],
                    key: d["@id"],
                })),
        [departments]
    );

    const [firstName, setFirstName] = React.useState(editedCoordinatorFirstName);
    const [lastName, setLastName] = React.useState(editedCoordinatorLastName);
    const [email, setEmail] = React.useState(editedCoordinatorEmail);
    const [departmentsInput, setDepartmentsInput] = React.useState(departmentsInputDefault);
    const isEditMode = React.useMemo(() => editedCoordinatorEmail !== "", []);

    const onSubmitCallback = (event) => {
        const form = {
            firstName,
            lastName,
            departments: departmentsInput,
        };

        event.preventDefault();
        if (!isEditMode) {
            form.email = email;
        }
        onSubmit(form);
    };

    return (
        <form className="form grid grid__padding-10" onSubmit={onSubmitCallback}>
            <div className="col-md-50">
                <InputText
                    label="Prénom"
                    placeholder="Saisissez votre prénom"
                    name="firstName"
                    error={errors.firstName}
                    defaultValue={firstName}
                    required
                    onChange={setFirstName}
                />
            </div>
            <div className="col-md-50">
                <InputText
                    label="Nom"
                    placeholder="Saisissez votre nom"
                    name="lastName"
                    error={errors.lastName}
                    defaultValue={lastName}
                    required
                    onChange={setLastName}
                />
            </div>
            <div className="col-100">
                <InputEmail
                    label="Email"
                    placeholder="Saisissez votre email"
                    error={errors.email}
                    defaultValue={email}
                    required
                    onChange={setEmail}
                    disabled={isEditMode}
                />
            </div>
            <div className="col-100">
                <SelectMultiple
                    label="Vos départements"
                    multiple
                    placeholder="Sélectionner un ou plusieurs départements"
                    options={departmentsOptions}
                    defaultSelected={departmentsInput}
                    required
                    onChange={setDepartmentsInput}
                />
            </div>
            <div className="form__group col-100 text-center">
                <Link
                    to={{
                        pathname: "/configuration",
                        state: { from: location },
                        tab: "coordinator",
                    }}
                >
                    <Button className="button__secondaryButton">
                        <Icon name="arrow-left" className="mr-1" /> Retour
                    </Button>
                </Link>
                <Button type="submit" className="button__primaryButton" isLoading={isLoading}>
                    Enregistrer
                </Button>
            </div>
        </form>
    );
};

CoordinatorForm.propTypes = {
    editedCoordinator: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        departments: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                "@id": PropTypes.string.isRequired,
                code: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
            }).isRequired
        ),
    }),
    departments: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            "@id": PropTypes.string.isRequired,
            code: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired
    ).isRequired,
    errors: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
    }),
    onSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export default CoordinatorForm;
