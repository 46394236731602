import React from "react";
import PropTypes from "prop-types";

import { useParams } from "react-router-dom";

import { connect } from "react-redux";
import { tryEditPatient } from "../../actions/patients";

import moment from "moment";
import { Animated } from "react-animated-css";
import { DefaultLoader, ImagePreview } from "@rdcs/dap-front-library";

import CoordinatorPatientLayout from "../../components/Coordinator/CoordinatorPatientLayout";
import CoordinatorPatientCoordonneesForm from "../../components/Coordinator/CoordinatorPatientCoordonneesForm";
import { getInitials, getViolations } from "../../utils/utils";

const CoordinatorPatientCoordonneesScreen = ({ auth, patients, tryEditPatient }) => {
    const params = useParams();
    const patientId = React.useMemo(() => parseInt(params.id), [params.id]);

    React.useEffect(() => {
        document.title = "Timkl - Coordonnées patient";
    }, []);

    const patient = patients.singlePatient;

    return (
        <CoordinatorPatientLayout activeNav="coordonnees">
            <div className="informationsList">
                <div className="page-single-patient">
                    {patients.singlePatientLoading ? (
                        <DefaultLoader color="#CCC" />
                    ) : (
                        patient && (
                            <Animated animationIn="fadeIn">
                                <div className="proSanteList__item">
                                    <div className="proSanteList__itemVisuel">
                                        {patient.avatar ? (
                                            <ImagePreview filename={patient.avatar.filename} className="proSanteList__itemVisuel-img" />
                                        ) : (
                                            <span className="proSanteList__itemVisuel-initiales">{getInitials(patient.firstName, patient.lastName)}</span>
                                        )}
                                    </div>
                                    <div>
                                        <p className="title-4">{patient.firstName + " " + patient.lastName}</p>
                                        <p className="proSanteList__itemSubtitle">
                                            {patient.email}
                                            {patient.phoneNumber ? "  ::  " + patient.phoneNumber : ""}
                                        </p>
                                        <p className="proSanteList__itemSubtitle">Né(e) le : {moment(patient.dateOfBirth).format("DD/MM/YYYY")}</p>
                                        <p className="proSanteList__itemSubtitle">Numéro de sécurité sociale {patient.ssn}</p>
                                    </div>
                                </div>
                                <div className="blockItem">
                                    <CoordinatorPatientCoordonneesForm
                                        submit={(patientValue) => tryEditPatient(auth, patientId, patientValue)}
                                        profile={patient}
                                        profileEditLoading={patients.patientEditLoading}
                                        violations={getViolations(patients.patientEditError)}
                                    />
                                </div>
                            </Animated>
                        )
                    )}
                </div>
            </div>
        </CoordinatorPatientLayout>
    );
};

CoordinatorPatientCoordonneesScreen.propTypes = {
    auth: PropTypes.object.isRequired,
    patients: PropTypes.shape({
        singlePatient: PropTypes.object,
        singlePatientLoading: PropTypes.bool.isRequired,
        patientEditLoading: PropTypes.bool.isRequired,
        patientEditError: PropTypes.oneOfType([PropTypes.shape({ violations: PropTypes.object }), PropTypes.bool]).isRequired,
    }).isRequired,
    tryEditPatient: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    patients: state.patients,
});

const mapDispatchToProps = {
    tryEditPatient,
};

export default connect(mapStateToProps, mapDispatchToProps)(CoordinatorPatientCoordonneesScreen);
