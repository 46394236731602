import React from "react";
import PropTypes from "prop-types";

import { InputText, TextArea, SelectMultiple } from "@rdcs/dap-front-library";

const FreeQuestion = ({ name, question, diagnosticAnswers = [] }) => {
    let answerList = [];
    for (let i = 1; question[`answer${i}`]; i++) {
        answerList.push(question[`answer${i}`]);
    }
    question = { ...question, answerList };

    const diagnosticAnswer = React.useMemo(() => {
        const diagnosticAnswerLocal = Object.values(diagnosticAnswers)?.find((e) => e.question === question.label);

        return (
            diagnosticAnswerLocal && {
                ...diagnosticAnswerLocal,
                values: [...diagnosticAnswerLocal.values],
            }
        );
    }, [diagnosticAnswers]);
    const [isOtherChoiceSelectedDefault, otherChoiceContentDefault] = React.useMemo(() => {
        let defaultSelected = false;
        let defaultOtherContent = "";

        if (diagnosticAnswer && question.fieldType === "choices") {
            if ((defaultSelected = diagnosticAnswer.values.includes("Autre"))) {
                defaultOtherContent = diagnosticAnswer.values[diagnosticAnswer.values.length - 1];
                diagnosticAnswer.values.pop();
            }
        }
        return [defaultSelected, defaultOtherContent];
    }, []);
    const [isOtherChoiceSelected, setIsOtherChoiceSelected] = React.useState(isOtherChoiceSelectedDefault);

    return (
        <div className="col-100">
            {question.fieldType === "choices" ? (
                <div className="d-flex">
                    <div className="w-2">
                        <SelectMultiple
                            name={name}
                            label={question.label}
                            required={question.mandatory}
                            multiple={true}
                            defaultSelected={diagnosticAnswer?.values}
                            options={question.answerList.map((q) => ({
                                title: q,
                                value: q,
                                pId: -1,
                                id: q,
                            }))}
                            onChange={(val) => {
                                setIsOtherChoiceSelected(val.includes("Autre"));
                            }}
                            onTransformBeforeChange={
                                question.hasOtherChoice
                                    ? (val) => {
                                          const index = val.indexOf("Autre");

                                          if (index !== -1 && index !== val.length - 1) {
                                              val.splice(index, 0, val.pop());
                                          }
                                          return val;
                                      }
                                    : null
                            }
                        />
                    </div>
                    {question.hasOtherChoice && isOtherChoiceSelected && (
                        <div className="w-2 pl-2">
                            <TextArea label="Précisez :" name={`${name}-other`} defaultValue={otherChoiceContentDefault} />
                        </div>
                    )}
                </div>
            ) : question.fieldLength === "short" ? (
                <div className="w-2">
                    <InputText name={name} label={question.label} required={question.mandatory} defaultValue={diagnosticAnswer?.values[0]} />
                </div>
            ) : (
                <div className="w-2">
                    <TextArea name={name} label={question.label} required={question.mandatory} defaultValue={diagnosticAnswer?.values[0]} />
                </div>
            )}
        </div>
    );
};

FreeQuestion.propTypes = {
    name: PropTypes.string.isRequired,
    question: PropTypes.object.isRequired,
    diagnosticAnswers: PropTypes.arrayOf(PropTypes.object),
};

export default React.memo(FreeQuestion);
