import React from "react";
import PropTypes from "prop-types";
import { Link, useNavigate, useParams } from "react-router-dom";

import { connect } from "react-redux";

import { tryEditRecommendation, tryFetchRecommendations, clearEditRecommendation } from "../../actions/recommendations";
import { tryFetchTreatments } from "../../actions/treatments";
import { tryFetchComplications } from "../../actions/complications";
import { tryFetchMeasureTypes } from "../../actions/measures";
import { tryFetchPartners } from "../../actions/partners";

import { DefaultLoader } from "@rdcs/dap-front-library";

import RecommendationForm from "../../components/Recommendations/RecommendationForm";
import AppLayout from "../../components/Layout/AppLayout";
import { constant } from "../../utils/utils";

const breadcrumbs = [{ label: "Recommandations", link: "/recommandations" }, { label: "Éditer une recommandation" }];

const RecommendationsEditScreen = ({
    auth,
    treatments: { treatments },
    complications: { list: complications },
    measures,
    partners: { list: partners },
    recommendations,
    tryFetchTreatments,
    tryFetchComplications,
    tryFetchMeasureTypes,
    tryFetchPartners,
    tryFetchRecommendations,
    tryEditRecommendation,
    clearEditRecommendation,
}) => {
    const navigate = useNavigate();
    const { id } = useParams();

    const [loading, setLoading] = React.useState(true);

    const pathologies = React.useMemo(() => constant("pathologies"), []);
    const editedRecommendationId = React.useMemo(() => parseInt(id), [id]);
    const editedRecommendation = React.useMemo(
        () => recommendations.list.find((recommendation) => recommendation.id === editedRecommendationId),
        [editedRecommendationId, recommendations.list]
    );

    React.useEffect(() => {
        document.title = "Timkl - Recommendations";

        Promise.all([
            tryFetchTreatments(auth),
            tryFetchComplications(auth),
            tryFetchMeasureTypes(auth),
            tryFetchPartners(auth),
            tryFetchRecommendations(auth),
            clearEditRecommendation(auth),
        ]).finally(() => setLoading(false));
    }, []);

    React.useEffect(() => {
        if (!loading && editedRecommendation === undefined) {
            navigate({ pathname: "/recommandations" });
        }
    }, [loading, editedRecommendation]);

    const editRecommendation = (recoId, form) => {
        const allPathologies = pathologies.map((pathology) => form.pathology.includes(pathology));
        const formattedForm = {
            ...form,
            pathologyDt1: allPathologies[0],
            pathologyDt2: allPathologies[1],
            pathologyGestational: allPathologies[2],
            pathologyOther: allPathologies[3],
        };
        tryEditRecommendation(auth, recoId, formattedForm).then(() => {
            navigate({ pathname: "/recommandations" });
        });
    };

    return (
        <AppLayout navActive={1} breadcrumbs={breadcrumbs}>
            <h1 className="title-1">
                <Link to="/recommandations" className="breadcrumb__item">
                    Recommandations
                </Link>
                <span className="mx-1">{">"}</span>
                Éditer une recommandation
            </h1>
            <div>
                {loading ? (
                    <DefaultLoader />
                ) : (
                    <RecommendationForm
                        editedRecommendation={editedRecommendation}
                        treatments={treatments}
                        complications={complications}
                        measures={measures}
                        partners={partners}
                        recommendations={recommendations}
                        isLoading={recommendations.editLoading}
                        errors={recommendations.editError.violations || {}}
                        onSubmit={(form) => editRecommendation(editedRecommendation.id, form)}
                    />
                )}
            </div>
        </AppLayout>
    );
};

RecommendationsEditScreen.propTypes = {
    /* Auth */
    auth: PropTypes.object.isRequired,

    /* Treatments */
    treatments: PropTypes.object.isRequired,
    tryFetchTreatments: PropTypes.func.isRequired,

    /* complications */
    complications: PropTypes.object.isRequired,
    tryFetchComplications: PropTypes.func.isRequired,

    /* measures */
    measures: PropTypes.object.isRequired,
    tryFetchMeasureTypes: PropTypes.func.isRequired,

    /* Partners */
    partners: PropTypes.object.isRequired,
    tryFetchPartners: PropTypes.func.isRequired,

    /* recommendations */
    recommendations: PropTypes.object.isRequired,
    tryFetchRecommendations: PropTypes.func.isRequired,
    tryEditRecommendation: PropTypes.func.isRequired,
    clearEditRecommendation: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    treatments: state.treatments,
    complications: state.complications,
    measures: state.measures,
    partners: state.partners,
    recommendations: state.recommendations,
});

const mapDispatchToProps = {
    tryFetchRecommendations,
    tryFetchTreatments,
    tryFetchComplications,
    tryFetchMeasureTypes,
    tryFetchPartners,
    tryEditRecommendation,
    clearEditRecommendation,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecommendationsEditScreen);
