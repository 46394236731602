import React from "react";

import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";

import { Button, Form, InputText, Icon, SelectMultiple } from "@rdcs/dap-front-library";

const PointOfAttention = ({
    pointsOfAttention,
    editedPointOfAttention,
    editedPointOfAttention: { name: editedPointOfAttentionName = "", parent: editedPointOfAttentionParent = null } = {},
    errors,
    onSubmit,
    isLoading,
}) => {
    const location = useLocation();

    const onSubmitCallback = (formDatas) => {
        onSubmit({
            ...formDatas,
            parent: formDatas.parent === -1 ? null : formDatas.parent,
        });
    };

    const getDisabledIds = React.useCallback(
        (editedItem, ids = []) => {
            ids.push(editedItem.id);
            pointsOfAttention.forEach((item) => {
                if (item.parent && item.parent.id === editedItem.id) {
                    getDisabledIds(item, ids);
                }
            });
            return ids;
        },
        [pointsOfAttention]
    );

    const getPointsOfAttentionOptions = React.useMemo(() => {
        const firstChoiceOption = {
            id: -1,
            title: "Aucun point de vigilance parent",
            value: -1,
            key: -1,
            pId: 0,
        };

        const disabledIds = editedPointOfAttention ? getDisabledIds(editedPointOfAttention) : [];

        return [
            firstChoiceOption,
            ...pointsOfAttention.map((item) => ({
                id: item.id,
                title: item.name,
                value: item["@id"],
                key: item["@id"],
                pId: item.parent ? item.parent.id : 0,
                disabled: disabledIds.includes(item.id),
            })),
        ];
    }, [getDisabledIds, editedPointOfAttention]);

    return (
        <Form className="form grid grid__padding-10" onSubmit={onSubmitCallback}>
            <div className="col-md-100">
                <InputText
                    label="Nom du point de vigilance"
                    placeholder="Saisissez le nom du point de vigilance"
                    name="name"
                    error={errors.name}
                    defaultValue={editedPointOfAttentionName}
                    required
                    //onChange={setName}
                />
            </div>
            <div className="col-100">
                <SelectMultiple
                    label="point de vigilance parente"
                    placeholder="Sélectionnez un point de vigilance"
                    multiple={false}
                    defaultSelected={(editedPointOfAttentionParent && editedPointOfAttentionParent["@id"]) || null}
                    options={getPointsOfAttentionOptions}
                    name="parent"
                />
            </div>
            <div className="form__group col-100 text-center">
                <Link
                    to={{
                        pathname: "/configuration",
                        state: { from: location },
                        tab: "points-of-attention",
                    }}
                >
                    <Button className="button__secondaryButton">
                        <Icon name="arrow-left" className="mr-1" /> Retour
                    </Button>
                </Link>
                <Button type="submit" className="button__primaryButton" isLoading={isLoading}>
                    Enregistrer
                </Button>
            </div>
        </Form>
    );
};

PointOfAttention.propTypes = {
    pointsOfAttention: PropTypes.arrayOf(
        PropTypes.shape({
            parent: PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            }),
        })
    ).isRequired,
    editedPointOfAttention: PropTypes.shape({
        "@id": PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        parent: PropTypes.shape({ "@id": PropTypes.string }),
        name: PropTypes.string,
    }),
    errors: PropTypes.shape({
        name: PropTypes.string,
    }),
    onSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export default PointOfAttention;
