import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { tryFetchDiagnosticRecommendations } from "../../actions/patients";

import { DefaultLoader, Icon, PageTitle } from "@rdcs/dap-front-library";

import CoordinatorPatientLayout from "../../components/Coordinator/CoordinatorPatientLayout";

const CoordinatorPatientInformationsScreen = ({ auth, patients, tryFetchDiagnosticRecommendations }) => {
    const recosInfo = React.useMemo(
        () => patients.diagnosticRecommendations.filter((item) => item.recommendation.type === "information"),
        [patients.diagnosticRecommendations]
    );

    const onPatientLoaded = React.useCallback((singlePatient) => {
        tryFetchDiagnosticRecommendations(auth, singlePatient);
    }, []);

    return (
        <PageTitle title="Timkl - Mes Patients">
            <CoordinatorPatientLayout activeNav="infos" onPatientLoaded={onPatientLoaded}>
                <div className="contentHolder__title">
                    <div className="form__group d-flex align-items-center">
                        <Icon name="informations" className="contentHolder__title-icon mr-1" />
                        <h1 className="title-1">Informations à donner</h1>
                    </div>
                </div>

                <div className="informationsList">
                    {patients.diagnosticRecommendationsLoading ? (
                        <DefaultLoader />
                    ) : recosInfo.length > 0 ? (
                        recosInfo.map((info) => (
                            <div key={info.id} className="informationsList__item">
                                <a href={info.recommendation.source} className="informationsList__item-sourceLink" target="_blank" rel="noopener noreferrer">
                                    <Icon name="puce-reco" />
                                    <Icon name="pdf" className="sourceIcon" />
                                </a>
                                <div className="informationsList__item-title">{info.recommendation.title}</div>
                                <div className="informationsList__item-description">{info.recommendation.description}</div>
                            </div>
                        ))
                    ) : (
                        <div>Aucune information</div>
                    )}
                </div>
            </CoordinatorPatientLayout>
        </PageTitle>
    );
};

CoordinatorPatientInformationsScreen.propTypes = {
    auth: PropTypes.object.isRequired,
    patients: PropTypes.object.isRequired,
    tryFetchDiagnosticRecommendations: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, patients }) => ({ auth, patients });

const mapDispatchToProps = {
    tryFetchDiagnosticRecommendations,
};

export default connect(mapStateToProps, mapDispatchToProps)(CoordinatorPatientInformationsScreen);
