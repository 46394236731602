import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { connect } from "react-redux";

import { DefaultLoader, Icon, InputDateTime, InputSymbol, Select, useDidUpdate } from "@rdcs/dap-front-library";
import Moment from "moment";

import CoordinatorPatientLayout from "../../../components/Coordinator/CoordinatorPatientLayout";
import { tryCreateMeasure, tryFetchMeasures, tryFetchMeasureTypes } from "../../../actions/measures";
import { getViolations } from "../../../utils/utils";

import useState from "./useState";

const CoordinatorPatientDataScreen = ({ auth, measures, tryFetchMeasures, tryFetchMeasureTypes, tryCreateMeasure }) => {
    const { id } = useParams();
    const patientId = React.useMemo(() => parseInt(id), []);

    const patientMeasure = React.useMemo(() => measures.list.filter((measure) => measure.patient["@id"] === `/patients/${id}`), [measures.list, id]);

    const [{ loading, measures: measuresState, mainMeasures, othersMeasures }, dispatch] = useState();

    const load = () => dispatch({ type: "SET_LOADING", loading: true });
    const endLoad = () => dispatch({ type: "SET_LOADING", loading: false });

    const fetchMeasures = () => {
        const promises = [tryFetchMeasures(patientId)];

        if (measures.measureTypesList.length === 0) {
            promises.push(tryFetchMeasureTypes(auth));
        }

        return Promise.all(promises).catch(endLoad);
    };

    React.useEffect(() => {
        document.title = "Timkl - Patients";

        fetchMeasures();
    }, []);

    useDidUpdate(() => {
        /* Define all defaults vars BEGINNING */
        const numberOfItemsToPush = 3; // The number of items to show at beginning
        const stateOfShowAddMeasure = false; // The state of opening add measure
        const addMeasureData = {
            // I discard type because API Platform do not detect if measureType is empty if I put empty string or null
            patient: `/patients/${id}`,
            value: "",
            source: "DAP",
            date: "",
        }; // Default state for adding a measure

        /* Define main categories BEGINNING */
        // Create an array with a value for each measure type
        const mainMeasuresShowMoreItemArray = [];
        const mainMeasuresShowAddItemArray = [];
        const mainMeasuresAddMeasureDataArray = [];
        const mainCategoriesName = ["HbA1c"]; // Choose a list of main categories to show
        const mainPatientMeasures = measures.measureTypesList.filter((measure) => mainCategoriesName.some((categoryName) => measure.name === categoryName));
        mainPatientMeasures.forEach(() => {
            mainMeasuresShowMoreItemArray.push(numberOfItemsToPush); // mainMeasuresShowMoreItemArray = [3, 3, 3, 3, 3]*
            mainMeasuresShowAddItemArray.push(stateOfShowAddMeasure); // mainMeasuresShowAddItemArray = [false, false, false, false, false]
            mainMeasuresAddMeasureDataArray.push(addMeasureData); // mainMeasuresAddMeasureDataArray = [{patient: "".....}, {patient: "".....}, {patient: "".....}, {patient: "".....}, {patient: "".....}]
        });
        /* Define main categories END */

        /* Define others categories BEGINNING */
        const othersPatientMeasures = measures.measureTypesList.filter((measure) => !mainCategoriesName.some((categoryName) => measure.name === categoryName));
        /* Define others categories END */

        /* Define all defaults vars END */

        dispatch({
            type: "SET_MESURE",
            measures: patientMeasure,
            mainMeasures: {
                measureTypes: mainPatientMeasures.sort((a, b) => (a.title < b.title ? -1 : 1)),
                showMoreItem: mainMeasuresShowMoreItemArray,
                showAddItem: mainMeasuresShowAddItemArray,
                addMeasureData: mainMeasuresAddMeasureDataArray,
            },
            othersMeasures: {
                ...othersMeasures,
                measureTypes: othersPatientMeasures.sort((a, b) => (a.title < b.title ? -1 : 1)),
                addMeasureData: {
                    ...addMeasureData,
                    unit: "",
                },
            },
        });
    }, [measures.list, measures.measureTypesList]);

    return (
        <CoordinatorPatientLayout activeNav="data">
            <div className=" form__group d-flex align-items-center">
                <Icon name="informations" className="contentHolder__title-icon mr-2" />
                <h1 className="title-1">Données du patient</h1>
            </div>
            {loading ? (
                <DefaultLoader color="#CCC" />
            ) : (
                <div>
                    {/* Main categories patient's datas (defined in defineDefaultState() function in mainCategoriesName constant) */}
                    {mainMeasures.measureTypes.map((item, index) => (
                        <div key={index} className="dataPatientlist">
                            <div>
                                <div className="dataPatientlist__header">
                                    <span className="dataPatientlist__headerName">{item.name}</span>
                                    <span className="dataPatientlist__headerAction">
                                        <span
                                            className="dataPatientlist__headerAction-item"
                                            onClick={() => dispatch({ type: "ADD_MEASURE", index, item: item["@id"] })}
                                        >
                                            <Icon className="dataPatientlist__headerAction-content" name="add" size="30px" />
                                            <span className="dataPatientlist__headerAction-content">Ajouter une mesure {item.name}</span>
                                        </span>
                                    </span>
                                </div>
                                {mainMeasures.showAddItem[index] && (
                                    <form
                                        className="dataPatientItem dataPatientItem--action"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            tryCreateMeasure(auth, mainMeasures.addMeasureData[index]).then(() => {
                                                load();
                                                fetchMeasures();
                                            });
                                        }}
                                    >
                                        <div className="dataPatientItem__title">{item.name}</div>
                                        <div className="dataPatientItem__form">
                                            <div className="dataPatientItem__inputGroup inlineForm">
                                                <InputDateTime
                                                    label="Date"
                                                    onChange={(dateValue) => dispatch({ type: "CREATE_MEASURE_SET_DATE", date: dateValue, index })}
                                                    error={getViolations(measures.createError).date}
                                                    required
                                                    requiredNotInLabel
                                                />
                                            </div>
                                            <div className="dataPatientItem__inputGroup inlineForm">
                                                <InputSymbol
                                                    label="Valeur"
                                                    symbol={item.unit}
                                                    onChange={(value) => dispatch({ type: "CREATE_MEASURE_SET_VALUE", value, index })}
                                                    error={getViolations(measures.createError).value}
                                                    required
                                                    requiredNotInLabel
                                                />
                                            </div>
                                        </div>
                                        <div className="dataPatientItem__right">
                                            <button type="submit">
                                                <Icon className="dataPatientItem__submitForm" name="check-circle" size="32px" />
                                                <span className="dataPatientItem__submitForm">Valider l'ajout</span>
                                            </button>
                                        </div>
                                    </form>
                                )}
                                {measuresState
                                    .filter((measure) => measure.type["@id"] === item["@id"])
                                    .sort((a, b) => (a.date > b.date ? -1 : 1))
                                    .slice(0, mainMeasures.showMoreItem[index])
                                    .map((measure, indexMeasure) => (
                                        <div key={indexMeasure} className="dataPatientItem">
                                            <div className="dataPatientItem__title">
                                                {measure.type.name}
                                                <span className="dataPatientItem__titleSubtitle">{Moment(measure.date).format("[Le ] Do MMMM YYYY")}</span>
                                            </div>
                                            <div className="dataPatientItem__right">
                                                <div className="dataPatientItem__percent">{measure.value + " " + measure.type.unit}</div>
                                            </div>
                                        </div>
                                    ))}
                                <div className="dataPatientItem__footerAction">
                                    {measuresState.filter((measure) => measure.type["@id"] === item["@id"]).length > mainMeasures.showMoreItem[index] && (
                                        <button
                                            className="dataPatientItem__footerAction-button"
                                            onClick={() => dispatch({ type: "MAIN_MEASURES_PREVIOUS_MEASURE", index })}
                                        >
                                            Mesures précédentes
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* Others categories patient's datas */}
                    <div className="dataPatientlist">
                        <div>
                            <div className="dataPatientlist__header">
                                <span className="dataPatientlist__headerName">Autres données</span>
                                <span className="dataPatientlist__headerAction">
                                    <span className="dataPatientlist__headerAction-item" onClick={() => dispatch({ type: "OTHER_MEASURES_TOGGLE_FILTER" })}>
                                        <Icon className="dataPatientlist__headerAction-content" name="filter" size="16px" />
                                        <span className="dataPatientlist__headerAction-content">Filtrer</span>
                                    </span>
                                    <span className="dataPatientlist__headerAction-item" onClick={() => dispatch({ type: "OTHER_MEASURES_ADD_NEW_ITEM" })}>
                                        <Icon className="dataPatientlist__headerAction-content" name="add" size="30px" />
                                        <span className="dataPatientlist__headerAction-content">Ajouter une autre donnée</span>
                                    </span>
                                </span>
                            </div>
                            {othersMeasures.showFilterItem && (
                                <div className="dataPatientItem dataPatientItem--action">
                                    <div className="dataPatientItem__form">
                                        <div className="dataPatientItem__inputGroup dataPatientItem__inputGroup--selectFilter inlineForm">
                                            <Select
                                                label="Afficher"
                                                options={[
                                                    {
                                                        label: "Toutes les données",
                                                        value: "",
                                                    },
                                                    ...othersMeasures.measureTypes.map((measure) => ({
                                                        label: measure.name,
                                                        value: measure["@id"],
                                                    })),
                                                ]}
                                                onChange={(val) => dispatch({ type: "OTHER_MEASURES_FILTER_CATEGORY", category: val })}
                                            />
                                        </div>
                                        <div className="dataPatientItem__right">
                                            <div className="dataPatientItem__inputGroup inlineForm">
                                                <div className="dataPatientItem__filterInput">
                                                    <InputDateTime
                                                        label="de"
                                                        displayFormat="MMMM yyyy"
                                                        defaultValue={new Date(othersMeasures.filter.startDate)}
                                                        onChange={(val) => dispatch({ type: "OTHER_MEASURES_FILTER_START_DATE", date: val })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="dataPatientItem__inputGroup inlineForm">
                                                <div className="dataPatientItem__filterInput">
                                                    <InputDateTime
                                                        label="à"
                                                        displayFormat="MMMM yyyy"
                                                        defaultValue={new Date(othersMeasures.filter.endDate)}
                                                        onChange={(val) => dispatch({ type: "OTHER_MEASURES_FILTER_END_DATE", date: val })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {othersMeasures.showAddItem && (
                                <form
                                    className="dataPatientItem dataPatientItem--action"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        tryCreateMeasure(auth, othersMeasures.addMeasureData);
                                    }}
                                >
                                    <div className="dataPatientItem__infos">
                                        <div className="dataPatientItem__form">
                                            <div className="dataPatientItem__inputGroup inlineForm">
                                                <Select
                                                    label="Choisir"
                                                    options={[
                                                        {
                                                            label: "--Choisir--",
                                                            value: "",
                                                        },
                                                        ...othersMeasures.measureTypes.map((measure) => ({
                                                            label: measure.name,
                                                            value: measure["@id"],
                                                        })),
                                                    ]}
                                                    error={getViolations(measures.createError).type}
                                                    onChange={(val) => dispatch({ type: "OTHER_MEASURES_SET_TYPE", measureType: val })}
                                                    required
                                                    requiredNotInLabel
                                                />
                                            </div>
                                            <div className="dataPatientItem__inputGroup inlineForm">
                                                <InputDateTime
                                                    label="Date"
                                                    onChange={(val) => dispatch({ type: "OTHER_MEASURES_SET_DATE", date: val })}
                                                    error={getViolations(measures.createError).date}
                                                    required
                                                    requiredNotInLabel
                                                />
                                            </div>
                                            <div className="dataPatientItem__inputGroup inlineForm">
                                                <InputSymbol
                                                    label="Valeur"
                                                    symbol={othersMeasures.addMeasureData.unit}
                                                    onChange={(val) => dispatch({ type: "OTHER_MEASURES_SET_VALUE", value: val })}
                                                    error={getViolations(measures.createError).value}
                                                    required
                                                    requiredNotInLabel
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dataPatientItem__right">
                                        <button type="submit">
                                            <Icon className="dataPatientItem__submitForm" name="check-circle" size="32px" />
                                            <span className="dataPatientItem__submitForm">Valider l'ajout</span>
                                        </button>
                                    </div>
                                </form>
                            )}
                            {measuresState
                                .filter((measure) => {
                                    // Filter category
                                    if (othersMeasures.filter.category === "") {
                                        // Filter date
                                        if (othersMeasures.filter.startDate === "" || othersMeasures.filter.endDate === "") {
                                            return othersMeasures.measureTypes.some((category) => measure.type["@id"] === category["@id"]);
                                        } else {
                                            return othersMeasures.measureTypes.some(
                                                (category) =>
                                                    measure.type["@id"] === category["@id"] &&
                                                    measure.date > othersMeasures.filter.startDate &&
                                                    measure.date < othersMeasures.filter.endDate
                                            );
                                        }
                                    } else {
                                        if (measure.type["@id"] === othersMeasures.filter.category) {
                                            // Filter date
                                            if (othersMeasures.filter.startDate === "" || othersMeasures.filter.endDate === "") {
                                                return true;
                                            } else {
                                                return measure.date > othersMeasures.filter.startDate && measure.date < othersMeasures.filter.endDate;
                                            }
                                        }
                                    }
                                    return false;
                                })
                                .sort((a, b) => (a.date > b.date ? -1 : 1))
                                .slice(0, othersMeasures.showMoreItem)
                                .map((measure, indexMeasure) => (
                                    <div key={indexMeasure} className="dataPatientItem">
                                        <div className="dataPatientItem__infos">
                                            <p className="dataPatientItem__title">
                                                {measure.type.name}
                                                <span className="dataPatientItem__titleSubtitle">{Moment(measure.date).format("[Le ] Do MMMM YYYY")}</span>
                                            </p>
                                        </div>
                                        <div className="dataPatientItem__right">
                                            <div className="dataPatientItem__percent">{measure.value + " " + measure.type.unit}</div>
                                        </div>
                                    </div>
                                ))}
                            <div className="dataPatientItem__footerAction">
                                {measuresState.filter((measure) => {
                                    // Filter category
                                    if (othersMeasures.filter.category === "") {
                                        // Filter date
                                        if (othersMeasures.filter.startDate === "" || othersMeasures.filter.endDate === "") {
                                            return othersMeasures.measureTypes.some((category) => measure.type["@id"] === category["@id"]);
                                        } else {
                                            return othersMeasures.measureTypes.some(
                                                (category) =>
                                                    measure.type["@id"] === category["@id"] &&
                                                    measure.date > othersMeasures.filter.startDate &&
                                                    measure.date < othersMeasures.filter.endDate
                                            );
                                        }
                                    } else {
                                        if (measure.type["@id"] === othersMeasures.filter.category) {
                                            // Filter date
                                            if (othersMeasures.filter.startDate === "" || othersMeasures.filter.endDate === "") {
                                                return true;
                                            } else {
                                                return measure.date > othersMeasures.filter.startDate && measure.date < othersMeasures.filter.endDate;
                                            }
                                        }
                                    }
                                    return false;
                                }).length > othersMeasures.showMoreItem && (
                                    <button
                                        className="dataPatientItem__footerAction-button"
                                        onClick={() => dispatch({ type: "OTHER_MEASURES_PREVIOUS_MEASURE" })}
                                    >
                                        Mesures précédentes
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </CoordinatorPatientLayout>
    );
};

CoordinatorPatientDataScreen.propTypes = {
    auth: PropTypes.object.isRequired,
    measures: PropTypes.object.isRequired,
    tryFetchMeasures: PropTypes.func.isRequired,
    tryFetchMeasureTypes: PropTypes.func.isRequired,
    tryCreateMeasure: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    measures: state.measures,
});

const mapDispatchToProps = {
    tryFetchMeasures,
    tryFetchMeasureTypes,
    tryCreateMeasure,
};

export default connect(mapStateToProps, mapDispatchToProps)(CoordinatorPatientDataScreen);
