import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { userCan } from "../../reducers/auth";
import AccessDeniedExceptionScreen from "../../screens/Exceptions/AccessDeniedException";

const PrivateRoute = ({ allowedRoles, auth }) => {
    const location = useLocation();
    const isAuthenticated = auth.user !== null;
    const accessAllowed = userCan(allowedRoles);

    if (!accessAllowed) {
        if (isAuthenticated) {
            return <AccessDeniedExceptionScreen />;
        } else {
            return <Navigate to="/connexion" state={{ from: location, afterAuthPath: location.pathname }} replace />;
        }
    }

    return <Outlet />;
};

PrivateRoute.propTypes = {
    allowedRoles: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
    auth: PropTypes.shape({
        user: PropTypes.object,
    }),
};

const mapStateToProps = ({ auth }) => ({ auth });

export default connect(mapStateToProps)(PrivateRoute);
