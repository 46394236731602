import React, { useState } from "react";
import { Modal, InputDateTime, InputText, Button, Icon } from "@rdcs/dap-front-library";
import { connect } from "react-redux";
import API from "../../../utils/API";
import { useParams } from "react-router-dom";
import { isLocalAPI } from "../../../utils/utils";

import { tryCreatePatientAppointment } from "../../../actions/patients";

const AddVisit = ({ tryCreatePatientAppointment, user, accessToken, patient }) => {
    const modalRefAddVisit = React.useRef(null);

    const [topic, setTopic] = useState("");
    const [date, setDate] = useState(null);

    const { id } = useParams();
    const patientId = React.useMemo(() => parseInt(id), [id]);

    const [isLoading, setIsLoading] = React.useState(false);

    const handleClickAddVisit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        API.request({
            token: isLocalAPI ? accessToken : null,
            path: isLocalAPI ? `/pharmacists/${user.id}` : `/dap-hcp-patient/api/v1/customers/practitioners/rpps/${store.getState().auth.user.id}`,
        })
            .then((response) => {
                const data = {
                    currentTopic: topic,
                    date: date,
                    practitioner: response["@id"],
                    patientIri: `/patients/${patientId}`,
                    doneAt: date,
                };
                tryCreatePatientAppointment(data).then(() => {
                    setIsLoading(false);
                    modalRefAddVisit.current.toggleModal();
                });
            })
            .catch((error) => error.message);
    };

    const handleChangeTopic = (value) => {
        setTopic(value);
    };

    const handleChangeDate = (value) => {
        setDate(value);
    };

    return (
        <Modal
            buttonContent={
                <div className="d-flex align-items-center timkl__color--blue">
                    <Icon name="add-circle" />
                    <p className="ml-1 timkl__color--blue" style={{ marginTop: "-5px" }}>
                        Ajouter une visite à la liste
                    </p>
                </div>
            }
            ref={modalRefAddVisit}
        >
            <>
                <p className="timkl__color--blue-light"> Ajouter consulation</p>
                <InputText label="Nom de la consultation" name="topic" value={topic} onChange={handleChangeTopic}></InputText>

                <InputDateTime
                    placeholder="Date de la visite"
                    displayFormat="dd-MM-yyyy à H:mm"
                    showTimeSelect={true}
                    label="Date de la consultation"
                    onChange={handleChangeDate}
                    required
                />
                <div className="d-flex justify-center">
                    <Button className="mt-2 button__primaryButton" onClick={(e) => handleClickAddVisit(e)} isLoading={isLoading}>
                        Valider
                    </Button>
                </div>
            </>
        </Modal>
    );
};

const mapStateToProps = ({ auth: { user, accessToken }, patient }) => ({ user, accessToken, patient });

const mapDispatchToProps = { tryCreatePatientAppointment };

export default connect(mapStateToProps, mapDispatchToProps)(AddVisit);
